import React from "react";

import { useSelector } from "react-redux";
import { StyledModal } from "@aureskonnect/react-ui";

import { RootState } from "@store/.";

import "@components/Common/LoadingDataAnimation/Loading.css";
import "./index.css";

type DynamicTableWrapperContextType = {
    setActionColumnDisplay: React.Dispatch<React.SetStateAction<boolean>>;
    setLocalFilterActive: React.Dispatch<React.SetStateAction<boolean>>;
    setDataUpdated: React.Dispatch<React.SetStateAction<number | boolean>>;
    setRowData: React.Dispatch<React.SetStateAction<any>>;
    rowData: any;
    dataIsUpdated: number | boolean;
    filterActive: boolean;
    isActionColumnDisplayed?: boolean;
    setDataTable: React.Dispatch<React.SetStateAction<any>>;
    dataTable: any;
    isEdit: boolean;
    setIsEdit: React.Dispatch<React.SetStateAction<any>>;
    checkAccessUpdate: boolean;
    setCheckAccessUpdate: React.Dispatch<React.SetStateAction<boolean>>;
    setMultiAuthorizationProfileData: React.Dispatch<any>;
    multiAuthorizationProfileData: any;
    addedProfile: ProfileType;
    setAddedProfile: React.Dispatch<React.SetStateAction<ProfileType>>;
    setCheckProfileAuthModelModification: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    checkProfileAuthModelModification: boolean;
    setCloseProfileModificationModel: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    closeProfileModificationModel: boolean;
    setCheckModifMultiple: React.Dispatch<React.SetStateAction<boolean>>;
    checkModifMultiple: boolean;
};
type DynamicTableWrapperPropsType = {
    TableComponent: JSX.Element;
    ActionColumnComponent: JSX.Element;
    showFormsInModal?: boolean;
};
export const DynamicTableWrapperContext =
    React.createContext<DynamicTableWrapperContextType | null>(null);

export default function DynamicTableWrapper({
    TableComponent,
    ActionColumnComponent,
    showFormsInModal = false,
}: DynamicTableWrapperPropsType) {
    const [isActionColumnDisplayed, setActionColumnDisplay] =
        React.useState(false);
    const [filterActive, setLocalFilterActive] = React.useState(false);
    const [isEdit, setIsEdit] = React.useState(false);
    const [isDataUpdated, setDataUpdated] = React.useState<boolean | number>(
        false
    );
    const [rowData, setRowData] = React.useState<any>(null);
    const [dataTable, setDataTable] = React.useState<any>({});
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );
    const [checkAccessUpdate, setCheckAccessUpdate] =
        React.useState<boolean>(false);

    const [multiAuthorizationProfileData, setMultiAuthorizationProfileData] =
        React.useState<any>();

    const [addedProfile, setAddedProfile] = React.useState<ProfileType>({
        Désignation: "",
        Balise: "",
        Description: "",
        autorisation: {},
    });

    const [
        checkProfileAuthModelModification,
        setCheckProfileAuthModelModification,
    ] = React.useState<boolean>(false);

    const [closeProfileModificationModel, setCloseProfileModificationModel] =
        React.useState<boolean>(false);

    const [checkModifMultiple, setCheckModifMultiple] =
        React.useState<boolean>(false);

    const defaultContext: DynamicTableWrapperContextType = React.useMemo(
        () => ({
            setActionColumnDisplay,
            isActionColumnDisplayed,
            setLocalFilterActive,
            setDataUpdated,
            dataIsUpdated: isDataUpdated,
            filterActive,
            setRowData,
            rowData,
            setDataTable,
            dataTable,
            isEdit,
            setIsEdit,
            checkAccessUpdate,
            setCheckAccessUpdate,
            setMultiAuthorizationProfileData,
            multiAuthorizationProfileData,
            setAddedProfile,
            addedProfile,
            setCheckProfileAuthModelModification,
            checkProfileAuthModelModification,
            setCloseProfileModificationModel,
            closeProfileModificationModel,
            setCheckModifMultiple,
            checkModifMultiple,
        }),
        [
            filterActive,
            isDataUpdated,
            rowData,
            isActionColumnDisplayed,
            dataTable,
            isEdit,
            checkAccessUpdate,
            setCheckAccessUpdate,
            setMultiAuthorizationProfileData,
            multiAuthorizationProfileData,
            setAddedProfile,
            addedProfile,
            setCheckProfileAuthModelModification,
            checkProfileAuthModelModification,
            setCloseProfileModificationModel,
            closeProfileModificationModel,
            setCheckModifMultiple,
            checkModifMultiple,
        ]
    );

    const showActionColumnComponentOnside =
        isActionColumnDisplayed && !isTablet && !showFormsInModal;

    React.useEffect(() => {
        if (filterActive) setActionColumnDisplay(false);
    }, [filterActive]);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateColumns: showActionColumnComponentOnside
                    ? "2fr 1fr "
                    : "auto",
                gridColumnGap: "4px",
            }}
        >
            <DynamicTableWrapperContext.Provider value={defaultContext}>
                {TableComponent}

                {showActionColumnComponentOnside ? (
                    ActionColumnComponent
                ) : (
                    <StyledModal
                        size="lg"
                        centered
                        fullscreen
                        isOpen={
                            isActionColumnDisplayed &&
                            (isTablet || showFormsInModal)
                        }
                    >
                        {ActionColumnComponent}
                    </StyledModal>
                )}
            </DynamicTableWrapperContext.Provider>
        </div>
    );
}
