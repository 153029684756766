/* eslint-disable react/no-unused-prop-types */
import React from "react";

import { useTranslation } from "react-i18next";
import { AvForm } from "availity-reactstrap-validation";
import { CardHeader } from "reactstrap";
import {
    RefreshIcon,
    CrossIcon,
    StyledIconButton,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";

import { useDispatch, useSelector } from "react-redux";

import { setIsEditing } from "@store/features/editObserverSlice";
import { RootState } from "@store/index";
import { DynamicTableWrapperContext } from "../DynamicTableWrapper";

import LockIconWithToolTip from "../LockIconWithToolTip/LockIconWithToolTip";

export type PropsType = {
    title: string;
    refreshIconFunction?: Function;
    Icon?: JSX.Element;
};

export default function StyledCardHeader({
    title,
    refreshIconFunction,
}: //  Icon = <IconElement iconType="item" />,
PropsType) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const isEditing = useSelector(
        (state: RootState) => state.editObserverSlice.isEditing
    );

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const handleCrossIconClick = () => {
        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setIsEdit(false);
    };
    return (
        <CardHeader
            className="Headercard  d-flex
            justify-content-between"
        >
            <div className=" d-flex align-items-center justify-content-center">
                <h4
                    className="m-0 pl-2 ctl_txt_addUserAccount ctl_txt_modifyUserAcount pfl_txt_addProfile"
                    style={{ font: "normal normal 600 20px/27px Segoe UI" }}
                >
                    {t(title)}
                </h4>
            </div>

            <div className="d-flex align-items-center ">
                <div
                    className=" bd-highlight align-items-center"
                    style={
                        isEditing ? { pointerEvents: "none" } : { opacity: "1" }
                    }
                >
                    {refreshIconFunction !== undefined ? (
                        <AvForm
                            onSubmit={() => {
                                refreshIconFunction();
                            }}
                        >
                            <div
                                id="refreche"
                                className="ctl_btn_reset pfl_icn_reset"
                            >
                                <LockIconWithToolTip
                                    message={t("Reset the fields")}
                                    id="refreche"
                                />
                                <StyledIconButton icon="RefreshIcon">
                                    <RefreshIcon
                                        height={20}
                                        width={20}
                                        cursor="pointer"
                                    />
                                </StyledIconButton>
                            </div>
                        </AvForm>
                    ) : null}
                </div>
                <div
                    className=" bd-highlight align-items-center"
                    style={{ zIndex: "10", position: "relative" }}
                >
                    <StyledIconButton
                        style={{ display: "none" }}
                        icon="VerticalDotsIcon"
                    >
                        <VerticalDotsIcon
                            key="dropdownMenuButton1"
                            data-bs-toggle="dropdown"
                            cursor="pointer"
                            height={20}
                            width={20}
                        />
                    </StyledIconButton>
                </div>

                <div className=" bd-highlight align-items-center">
                    <AvForm
                        onSubmit={() => {
                            dispatch(setIsEditing(false));
                            handleCrossIconClick();
                        }}
                    >
                        <div
                            id="Flèche3"
                            className="ctl_icn_closeForm pfl_icn_closeForm"
                        >
                            <LockIconWithToolTip
                                message={t("Close")}
                                id="Flèche3"
                            />
                            <StyledIconButton icon="CrossIcon">
                                <CrossIcon
                                    cursor="pointer"
                                    height={20}
                                    width={20}
                                />
                            </StyledIconButton>
                        </div>
                    </AvForm>
                </div>
            </div>
        </CardHeader>
    );
}
