import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DominantColor } from "@components/Common/";

type ConfirmAccessModificationPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleManageAcesstOnClickEvent: Function;
    closeFirstModal: Function;
    user: any;
    handleSaveUserButtonOnClickEvent?: Function;
};

export default function ConfirmAccessModification({
    isOpen,
    setIsOpen,
    handleManageAcesstOnClickEvent,
    closeFirstModal,
    user,
    handleSaveUserButtonOnClickEvent,
}: ConfirmAccessModificationPropsType) {
    const { t } = useTranslation();

    const dominantColor: string = DominantColor();

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader
                toggle={() => {
                    handleSaveUserButtonOnClickEvent !== undefined &&
                        handleSaveUserButtonOnClickEvent(user);
                    setIsOpen(!isOpen);
                    closeFirstModal();
                }}
            >
                <span className="text-uppercase">{t("Confirmation")}</span>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to confirm the changes made ?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        handleSaveUserButtonOnClickEvent !== undefined &&
                            handleSaveUserButtonOnClickEvent(user);
                        setIsOpen(!isOpen);
                        closeFirstModal();
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    onClick={handleManageAcesstOnClickEvent}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
