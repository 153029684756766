import styled from "styled-components";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

export const Wrapper = styled.div<WrapperPropsType>`
    display: flex;
    flex-direction: column;
    ${({ collapsed }: WrapperPropsType) => {
        return collapsed ? "width:72px" : "width: 274px";
    }};

    min-height: 1077px;
    border-right: 1px solid #ddd;
    background-color: var(--dominantColor);
    .brand__clz {
        ${({ collapsed }: WrapperPropsType) => {
            return collapsed ? "width: 73px;height: 36px" : "";
        }};
    }

    .sidebar-menu__clz a {
        display: block;
        padding: 0.5rem 1rem;
        text-decoration: none;
        color: #ffffff;
    }

    .sidebar-menu__clz,
    .sidebar-menu__clz ul {
        padding: 0;
        margin: 0;
        list-style: none;
        color: #ffffff;
    }

    .sidebar-menu__clz .active {
        background: #dbdbdb;
        color: rgb(65, 98, 207);
    }

    .sidebar-menu__clz a:hover {
        color: white;
        opacity: 1;
    }

    .sidebar-menu__clz a {
        opacity: 0.7;
    }

    .sidebar-menu__clz a:active {
        background-color: #ffffff5e;
        border-left: solid;
        border-color: white;
        opacity: 1;
    }

    .sidebar-menu__clz ul a {
        padding-left: 2rem;
    }

    .sidebar-menu__clz li a:hover {
        background-color: #ffffff5e;
        border-left: solid;
        border-color: white;
    }

    .sidebar-menu__clz .mm-active li:hover {
        background-color: #ffffff5e;
        ${({ collapsed }: WrapperPropsType) => {
            return collapsed ? " " : "border-left: solid;";
        }};
        border-color: white;
    }

    .menuitem-collapsed-header__clz {
        padding-top: 10px;
        padding-left: 33px;
        background-color: #ffffff5e;
        border-color: white;
        padding-bottom: 6px;
    }
    .arrow {
        border: solid white;
        border-width: 0 2px 2px 0;
        padding: 2px;
    }
    .arrowRight {
        transform: rotate(46deg) translateY(-50%);
    }
    .arrowLeft {
        transform: rotate(-135deg) translateY(-50%);
    }

    .sidebar-menu__clz li {
        display: block;
        position: relative;
        line-height: 27px;
        text-align: left;
    }

    .sidebar-menu__clz li ul.collapsed-submenu__clz {
        display: none;
        background-color: #323333;
        z-index: 1;
        position: fixed;
        margin-left: 71px;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px;
    }

    .sidebar-menu__clz li ul.collapsed-submenu__clz li {
        display: block;
    }

    .sidebar-menu__clz li:hover ul.collapsed-submenu__clz {
        display: block;
    }
`;
