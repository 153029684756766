import React from "react";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    PlusIcon,
    PortraitIcon,
    StyledButtonWithIcon,
    StyledIconButton,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { AvForm } from "availity-reactstrap-validation";

import { RootState } from "@store/index";
import { setIsProfileListArchived } from "@store/features/profileSlice";

import { DominantColor, DynamicTableWrapperContext } from "@components/Common";
import HistoryModal from "@components/Common/Modals/HistoryModal";
import { PROFILE_ACCESS_ATHORIZATION_ID } from "@constants/index";

import ActionMultipleProfile from "./ActionMultipleProfile";


type CustomActionFiltertype = {
    accountNumber: any;
    handleSubmit: Function;
    selectedRows: any[];
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsManagePermissionsModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
    setIsAuthorisationProfileAlert: React.Dispatch<
        React.SetStateAction<boolean>
    >;

    handleManagePermissionsOnClickEvent: Function;
};

function CustomActionFilter({
    handleSubmit,
    accountNumber,
    selectedRows,
    setIsConfirmUnArchivedModal,
    setIsConfirmArchivedModal,
    setIsManagePermissionsModalOpened,
    setIsAuthorisationProfileAlert,
    handleManagePermissionsOnClickEvent,
}: CustomActionFiltertype) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const [isHistoryModalOpened, setIsHistoryModalOpened] =
        React.useState<boolean>(false);

    const dispatch = useDispatch();
    const isProfileListArchived = useSelector(
        (state: RootState) => state.profileSlice.isProfileListArchived
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );
    
    const handleProfileListViewClick = () => {
        return dispatch(setIsProfileListArchived(!isProfileListArchived));
    };

    function handleHistoryOnClickEvent(selectedRows: any) {
        setIsHistoryModalOpened(!isHistoryModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRows });
    }

    return (
        <React.Fragment>
            <div className="d-flex flex-row align-items-center ml-2">
                {!isProfileListArchived && (
                    <AvForm onSubmit={handleSubmit}>
                        {!dynamicTableWrapperContext?.isActionColumnDisplayed ? (
                            <div className="cmn_btn_openForm">
                                <StyledButtonWithIcon
                                    icon="PlusIcon"
                                    iconPosition="left"
                                    rounded
                                    variant={dominantColor}
                                    disabled={
                                        authorization[
                                            PROFILE_ACCESS_ATHORIZATION_ID
                                        ] !== undefined &&
                                        !authorization[
                                            PROFILE_ACCESS_ATHORIZATION_ID
                                        ].Modifie
                                    }
                                >
                                    {t("Add profile")}
                                </StyledButtonWithIcon>
                            </div>
                        ) : (
                            <StyledIconButton
                                style={{
                                    width: "48px",
                                    height: "48px",
                                }}
                                variant={dominantColor}
                                icon="PlusIcon"
                                className="bg-dark pfl_btn_closeForm"
                                rounded
                                disabled={false}
                            >
                                <PlusIcon height={20} width={20} fill="white" />
                            </StyledIconButton>
                        )}
                    </AvForm>
                )}
                <div className=" dropdown d-flex justify-content-end align-items-end">
                    <VerticalDotsIcon
                        className="cmn_drp_menu"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        style={{ cursor: "pointer" }}
                        height={25}
                        width={25}
                        fill="black"
                    />

                    <div className="dropdown-menu">
                        <div
                            className={`dropdown-item pl-1 ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ].Modifie
                                    ? ""
                                    : "disabled_clz"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleProfileListViewClick();
                            }}
                        >
                            {isProfileListArchived === false ? (
                                <div
                                    role="button"
                                    tabIndex={-3}
                                    className={`${
                                        accountNumber > 0 &&
                                        authorization[
                                            PROFILE_ACCESS_ATHORIZATION_ID
                                        ] !== undefined &&
                                        authorization[
                                            PROFILE_ACCESS_ATHORIZATION_ID
                                        ].Modifie
                                            ? ""
                                            : "disabled_clz"
                                    }`}
                                >
                                    <PortraitIcon width={35} height={25} />
                                    <span className="ml-2 pfl_btn_archivedProfile">
                                        {`${t(
                                            "Archived profile(s)"
                                        )}(${accountNumber})`}
                                    </span>
                                </div>
                            ) : (
                                <div
                                    role="button"
                                    tabIndex={-3}
                                    className={`dropdown-item pl-1 cmn_btn_activatedProfile ${
                                        accountNumber === 0
                                            ? "disabled_clz"
                                            : ""
                                    }`}
                                >
                                    <PortraitIcon width={35} height={25} />
                                    <span className="ml-2">
                                        {`${t(
                                            "Profile(s) activated"
                                        )} (${accountNumber})`}
                                    </span>
                                </div>
                            )}
                        </div>
                        <ActionMultipleProfile
                            isProfileListArchived={isProfileListArchived}
                            selectedRows={selectedRows}
                            setIsConfirmUnArchivedModal={
                                setIsConfirmUnArchivedModal
                            }
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                            setIsManagePermissionsModalOpened={
                                setIsManagePermissionsModalOpened
                            }
                            setIsAuthorisationProfileAlert={
                                setIsAuthorisationProfileAlert
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleHistoryOnClickEvent={
                                handleHistoryOnClickEvent
                            }
                            handleManagePermissionsOnClickEvent={
                                handleManagePermissionsOnClickEvent
                            }
                        />
                    </div>
                </div>
            </div>
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    type={4}
                />
            ) : null}
        </React.Fragment>
    );
}

export default CustomActionFilter;
