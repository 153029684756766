import React from "react";
import { toast } from "react-toastify";
import { ShieldExclamationIcon } from "@aureskonnect/react-ui";
import "./Style.css";

export default function RequirementToast(message: string): JSX.Element {
    return (
        <>
            {toast.error(message, {
                className: "responsive-toast",
                position: toast.POSITION.TOP_CENTER,
                autoClose: 10000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                icon: (
                    <ShieldExclamationIcon
                        height={25}
                        width={25}
                        fill="white"
                    />
                ),
            })}
        </>
    );
}
