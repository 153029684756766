import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { setIsProfileListArchived } from "@store/features/profileSlice";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";

import { PROFILE_ACCESS_ATHORIZATION_ID } from "@constants/index";
import { RootState } from "@store/index";

import { AddProfile } from "./ActionProfile/AddProfile";

import { ProfileList } from "./ProfileList";

export default function Profile() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    dispatch(setIsProfileListArchived(false));

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                {authorization[PROFILE_ACCESS_ATHORIZATION_ID] !== undefined &&
                authorization[PROFILE_ACCESS_ATHORIZATION_ID].Consult ? (
                    <>
                        <StyledBreadcrumb
                            items={[
                                {
                                    item: "Access",
                                    link: "#",
                                },
                                {
                                    item: t(selectedCustomer),
                                    link: "#",
                                },
                                {
                                    item: t("Configuration"),
                                    link: "#",
                                },
                                {
                                    item: t("Profile"),
                                    link: "#",
                                },
                            ]}
                        />
                        <Container fluid>
                            <HeaderComponent
                                title={t(
                                    "It is mandatory to create profiles and manage their permissions in order to assign them to users. Switching between active and archived profiles is possible."
                                )}
                            />
                            <DynamicTableWrapper
                                TableComponent={<ProfileList />}
                                ActionColumnComponent={<AddProfile />}
                            />
                        </Container>
                    </>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: "238px" }}
                    >
                        <img
                            alt="img"
                            src="./images/emptyAccounts.png"
                            style={{
                                width: "248px",
                                height: "248px",
                            }}
                        />
                        <span
                            style={{
                                width: "295px",
                                height: "40px",
                                textAlign: "left",
                                font: "normal normal 600 30px/40px Segoe UI",
                                color: "#495057",
                                marginLeft: "3rem",
                            }}
                        >
                            {t("No access allowed")}
                        </span>
                    </div>
                )}
            </motion.div>
        </React.Fragment>
    );
}
