import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DominantColor } from "@components/Common/";

type ConfirmDifferenceAccessModificationPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleManageAccessOnClickEvent: Function;
};

export default function ConfirmDifferenceAccessModification({
    isOpen,
    setIsOpen,
    handleManageAccessOnClickEvent,
}: ConfirmDifferenceAccessModificationPropsType) {
    const { t } = useTranslation();

    const dominantColor: string = DominantColor();

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader
                toggle={() => {
                    setIsOpen(!isOpen);
                }}
            >
                <span className="text-uppercase">{t("Confirmation")}</span>
            </ModalHeader>
            <ModalBody>
                {t(
                    "The accesses to the accounts of the selected users are configured differently. Do you want to continue ?"
                )}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    onClick={() => {
                        setIsOpen(!isOpen);
                        handleManageAccessOnClickEvent();
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
