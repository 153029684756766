import { createSlice } from "@reduxjs/toolkit";

type storeType = {
    dominantColor: string
};

const initialState: storeType = {
    dominantColor :  localStorage.getItem(
        "mainColor"
        
    )!== null ? (localStorage.getItem(
        "mainColor"
        
    )as string ) : "#323333"
};

export const colorSlice = createSlice({
    name: "colorSlice",
    initialState,
    reducers: {
        setDominantColor: (state: any, action: any) => {
            state.dominantColor = action.payload;
        },

        
    },
});

export const {
    setDominantColor,
} = colorSlice.actions;

export default colorSlice.reducer;
