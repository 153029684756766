import React, { StrictMode } from "react";

import ReactDOM from "react-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistStore } from "redux-persist";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

import * as serviceWorker from "./serviceWorker";
import store from "./store";
import App from "./App";
import "./i18n";

function Main() {
    const persister = persistStore(store);
    return (
        <StrictMode>
            <Provider store={store}>
                <PersistGate loading={null} persistor={persister}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </PersistGate>
                <ToastContainer />
            </Provider>
        </StrictMode>
    );
}
ReactDOM.render(<Main />, document.getElementById("root"));
serviceWorker.unregister();
