import { createSlice } from "@reduxjs/toolkit";

type StateType = {
    collapsed: boolean;
    isTablet: boolean;
    activeMenu: number;
};
const initialState: StateType = {
    collapsed: false,
    isTablet: false,
    activeMenu: -1,
};

export const layoutSlice = createSlice({
    name: "layout",
    initialState,
    reducers: {
        setCollapsed: (state: any) => {
            state.collapsed = !state.collapsed;
        },
        setIsTablet: (state: any, action: any) => {
            state.isTablet = action.payload;
        },
        setActiveMenu: (state: any, action: any) => {
            state.activeMenu = action.payload;
        },
    },
});

export const { setCollapsed, setIsTablet, setActiveMenu } = layoutSlice.actions;

export default layoutSlice.reducer;
