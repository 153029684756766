import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { DominantColor } from "../dominantColor";

type AuthorisationProfileModelType = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setIsManagePermissionsModalOpened: React.Dispatch<
        React.SetStateAction<boolean>
    >;
};

export function AuthorisationProfileModel({
    isOpen,
    setIsOpen,
    setIsManagePermissionsModalOpened,
}: AuthorisationProfileModelType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={() => setIsOpen(false)}>
                <span className="text-uppercase cmn_txt_titleAlert">
                    {t("Alert")}
                </span>
            </ModalHeader>
            <ModalBody className="cmn_txt_descriptionAlert">
                {t(
                    "The permissions of the selected profiles are configured differently."
                )}
                <br />
                {t("Would you like to continue ?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        setIsOpen(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    className="cmn_btn_validateAlert"
                    onClick={() => {
                        setIsManagePermissionsModalOpened(true);
                        setIsOpen(false);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
