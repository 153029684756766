import { StyledButton } from "@aureskonnect/react-ui";
import React from "react";
import { Modal, ModalBody, ModalFooter, ListGroup } from "react-bootstrap";
import { ModalHeader } from "reactstrap";
import { useTranslation } from "react-i18next";
import { DominantColor } from "@components/Common/dominantColor";
import Switch from "react-switch";
import "./index.css";
import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";

type AuthorizationMultiProfileModelType = {
    show: boolean;
    setShow: React.Dispatch<React.SetStateAction<boolean>>;
    selectedRows: any[];
};
type mutliCheckType = {
    Visible: boolean;
    Consult: boolean;
    Modifie: boolean;
};

export default function AuthorizationMultiProfileModel({
    show,
    setShow,
    selectedRows,
}: AuthorizationMultiProfileModelType) {
    const { t } = useTranslation();
    const dominantColor: string = DominantColor();
    const [checked, setChecked] = React.useState<mutliCheckType>({
        Visible: false,
        Consult: false,
        Modifie: false,
    });

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    function handleMultiAuthorizationProfile() {
        const autorisation: any = {};
        selectedRows.forEach((AuthorizationRow: any) => {
            autorisation[AuthorizationRow.id] = {
                Id: AuthorizationRow.id,
                Consult: checked.Consult,
                Modifie: checked.Modifie,
                Visible: checked.Visible,
                IdParent: AuthorizationRow.idParent,
            };
        });
        dynamicTableWrapperContext?.setMultiAuthorizationProfileData({
            ...autorisation,
        });
        dynamicTableWrapperContext?.setCheckProfileAuthModelModification(true);
        setShow(false);
        setChecked({
            Visible: false,
            Consult: false,
            Modifie: false,
        });
    }

    return (
        <Modal
            centered
            show={show}
            backdrop="static"
            style={{ background: "rgba(0, 0, 0, 0.6)" }}
        >
            <ModalHeader
                toggle={() => {
                    setShow(false);
                }}
            >
                <span>{t("BULK MODIFICATION")}</span>
            </ModalHeader>
            <ModalBody>
                <ListGroup>
                    <div>
                        <h6 className="blukModifactionTitle">
                            {t("Manage authorizations")}
                        </h6>
                    </div>
                    <ListGroup.Item className="rounded mx-2">
                        <div className="d-flex justify-content-between m-3">
                            <span>{t("Visibility")}</span>
                            <Switch
                                onChange={() => {
                                    setChecked({
                                        ...checked,
                                        Visible: !checked.Visible,
                                    });
                                }}
                                checked={checked.Visible}
                                offColor="#E30613"
                                onColor="#34C38F"
                            />
                        </div>
                        <div className="d-flex justify-content-between m-3">
                            <span>Consultation</span>
                            <Switch
                                onChange={() => {
                                    setChecked({
                                        ...checked,
                                        Consult: !checked.Consult,
                                    });
                                }}
                                checked={checked.Consult}
                                offColor="#E30613"
                                onColor="#34C38F"
                            />
                        </div>
                        <div className="d-flex justify-content-between m-3">
                            <span>Modification</span>
                            <Switch
                                onChange={() => {
                                    setChecked({
                                        ...checked,
                                        Modifie: !checked.Modifie,
                                    });
                                }}
                                checked={checked.Modifie}
                                offColor="#E30613"
                                onColor="#34C38F"
                            />
                        </div>
                    </ListGroup.Item>
                </ListGroup>
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setShow(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    onClick={() => {
                        handleMultiAuthorizationProfile();
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
