import { useEffect } from "react";
import NProgress from "nprogress";
import { useNavigate } from "react-router-dom";

type Props = {
    children: JSX.Element;
};

function AuthRouterAccess({ children }: Props): JSX.Element {
    useEffect(() => {
        NProgress.start();
        NProgress.done();
    }, []);

    const navigate = useNavigate();

    useEffect(() => {
        const uidEntity = localStorage.getItem("Uid_Entitie_User");

        if (uidEntity === null) {
            navigate("/");
        }
    });

    return children;
}

export default AuthRouterAccess;
