/* eslint-disable import/no-extraneous-dependencies */
import { CardBody, CardFooter, CardHeader } from "reactstrap";
import styled from "styled-components";

type CustomCardBodyPropsType = {
    isTablet: boolean;
    isVisible: boolean;
};

export const CustomCardHeader = styled(CardHeader)`
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    border: 1px solid rgba(0, 0, 0, 0.125);
    justify-content: space-between;
    background-color: white;
`;
export const CustomCardBody = styled(CardBody)<CustomCardBodyPropsType>`
    margin-right: 0;
    margin-left: 0px;
    padding: 0px;
    padding-top: 4px !important;

    border: 1px solid rgba(0, 0, 0, 0.125);
    overflow-y: auto;
    min-height: ${({ isTablet, isVisible }: CustomCardBodyPropsType) => {
        if (isTablet) return "50vh";
        if (isVisible) return "calc(100vh - 446px)";
        return "calc(100vh - 370px)";
    }};
    max-height: ${({ isTablet, isVisible }: CustomCardBodyPropsType) => {
        if (isTablet) return "calc(100vh - 200px)";
        if (isVisible) return "calc(100vh - 446px)";
        return "calc(100vh - 370px)";
    }};
`;

export const CustomCardFooter = styled(CardFooter)`
    background-color: white;
    border: 1px solid rgba(0, 0, 0, 0.125);
    border-top: 0px;
    border-radius: 0px 0px 12px 12px;
    padding: 0;
`;
