import React from "react";

import ReactSwitch from "react-switch";
import { updateUserChildsAccess } from "@store/features/accessSlice";
import { useDispatch, useSelector } from "react-redux";
import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import { RootState } from "@store/index";

export default function AllowAccess({ selectedRow }: any) {
    const access = useSelector((state: RootState) => state.accessSlice.access);

    const dispatch = useDispatch();

    const [isUserAuthenticated, setIsUserAuthenticated] = React.useState(false);

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    function handleSwitchOnClickEvent() {
        setIsUserAuthenticated(!isUserAuthenticated);
        dispatch(
            updateUserChildsAccess({
                userAccessStatus: !isUserAuthenticated,
                entityChildId: selectedRow.id,
            })
        );
        dynamicTableWrapperContext?.setCheckAccessUpdate(true);
    }

    React.useEffect(() => {
        access.forEach((Element: any) => {
            if (Element.id === selectedRow.id) {
                setIsUserAuthenticated(Element.authorization);
            }
        });
    }, [access, selectedRow.id]);

    return (
        <ReactSwitch
            className="ctl_icn_enableDisableAccess"
            uncheckedIcon={false}
            checkedIcon={false}
            handleDiameter={26}
            offColor="#f7b4b8"
            offHandleColor="#E30613"
            checked={isUserAuthenticated}
            onChange={() => {
                handleSwitchOnClickEvent();
            }}
            onColor="#c2eddd"
            onHandleColor="#34C38F"
            width={50}
            height={20}
        />
    );
}
