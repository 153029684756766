import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import React from "react";
import Switch from "react-switch";

type ConsultColmun = {
    Data: any;
    selectedRow: any;
    setlistSelectedData: Function;
    editProfile: boolean;
};

function EditColumn({
    Data,
    selectedRow,
    setlistSelectedData,
    editProfile,
}: ConsultColmun) {
    const [ischeckedEditColmun, setIsCheckedEditColmun] = React.useState(false);
    const dataProject: any = JSON.parse(
        localStorage.getItem("dataProject") as any
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    // Check For Simple or Mulitple Profile Selection Action
    let SelectedProfil = dynamicTableWrapperContext?.rowData?.original
        ? dynamicTableWrapperContext?.rowData?.original
        : dynamicTableWrapperContext?.rowData[0];
    // Check if multiple authorization action
    if (dynamicTableWrapperContext?.multiAuthorizationProfileData) {
        // check if Update profile action
        if (editProfile) {
            SelectedProfil.autorisation = {
                ...SelectedProfil.autorisation,
                ...dynamicTableWrapperContext?.multiAuthorizationProfileData,
            };
            // Add Profile Action
        } else {
            SelectedProfil = dynamicTableWrapperContext?.addedProfile;
            SelectedProfil.autorisation = {
                ...dataProject,
                ...dynamicTableWrapperContext?.multiAuthorizationProfileData,
            };
        }
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    function EditProfile() {
        if (
            SelectedProfil.autorisation[selectedRow.id] !== undefined &&
            SelectedProfil.autorisation[selectedRow.id].Modifie === true
        ) {
            if (ischeckedEditColmun === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: false,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: true,
                };
            }
        } else if (ischeckedEditColmun === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    // eslint-disable-next-line no-nested-ternary
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Visible
                        : SelectedProfil.autorisation[selectedRow.id] !==
                          undefined
                        ? SelectedProfil.autorisation[selectedRow.id].Visible
                        : false,
                Consult:
                    // eslint-disable-next-line no-nested-ternary
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Consult
                        : SelectedProfil.autorisation[selectedRow.id] !==
                          undefined
                        ? SelectedProfil.autorisation[selectedRow.id].Consult
                        : false,
                Modifie: true,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Visible
                        : SelectedProfil.autorisation[selectedRow.id].Visible,
                Consult:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Consult
                        : SelectedProfil.autorisation[selectedRow.id].Consult,
                Modifie: false,
            };
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function AddProfile() {
        if (
            SelectedProfil.autorisation[selectedRow.id] !== undefined &&
            SelectedProfil.autorisation[selectedRow.id].Modifie === true
        ) {
            if (ischeckedEditColmun === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: false,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Visible
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Visible,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie: true,
                };
            }
        } else if (ischeckedEditColmun === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie: true,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Visible,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie: false,
            };
        }
    }

    // ! Don't DELETE The useEffect
    // ! Used for multiple authorization to update table after multiple authorization switch changes.
    React.useEffect(() => {}, [
        SelectedProfil,
        dynamicTableWrapperContext?.multiAuthorizationProfileData,
    ]);

    return (
        <div className="d-flex align-items-center pfl_icn_enableDisableChange">
            <Switch
                onChange={() => {
                    setIsCheckedEditColmun(!ischeckedEditColmun);
                    if (editProfile) {
                        EditProfile();
                    } else {
                        AddProfile();
                    }

                    setlistSelectedData(Data);
                }}
                checked={
                    SelectedProfil &&
                    SelectedProfil.autorisation[selectedRow.id] !== undefined &&
                    (SelectedProfil as any).autorisation[selectedRow.id]
                        .Modifie === true
                        ? !ischeckedEditColmun
                        : ischeckedEditColmun
                }
                offColor="#E30613"
                onColor="#34C38F"
            />
        </div>
    );
}

export default EditColumn;
