/* eslint-disable sonarjs/no-duplicate-string */
import { createSlice } from "@reduxjs/toolkit";


type initialStateType = {
    data: {
        [uid: string]: {
            Id: string,
            Consult: boolean,
            Modifie: boolean,
            Visible: boolean,
            IdParent: string
        }
    }
}



const initialState: initialStateType = {
    data: {
        "5590fe0f-69fc-9ff9-699d-d9a90056a": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056a",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056b": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056b",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056c": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056d": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056d",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056e": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056e",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056f": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056f",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056g": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056g",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a90056h": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a90056h",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5075fe0f-852-9ff9-699d-d9a9defd990c": {
            "Id": "5075fe0f-852-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5075fe0f-852-9ff9-699d-d9a9defd990c"
        },
        "5090cccf-758-9ff9-699d-d9a9defd990c": {
            "Id": "5090cccf-758-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090cccf-758-9ff9-699d-d9a9defd990c"
        },
        "5090fbbb-951-9ff9-699d-d9a9defd990c": {
            "Id": "5090fbbb-951-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fbbb-951-9ff9-699d-d9a9defd990c"
        },
        "5090fe0f-758-9951-699d-d9a9defd990c": {
            "Id": "5090fe0f-758-9951-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-758-9951-699d-d9a9defd990c"
        },
        "5090fe0f-758-9nn1-699d-d9a9defd990c": {
            "Id": "5090fe0f-758-9nn1-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-758-9nn1-699d-d9a9defd990c"
        },
        "7594fe0f-69fc-9ff9-699ddddddefd9922": {
            "Id": "7594fe0f-69fc-9ff9-699ddddddefd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7590fe0f-69fc-9ff9-699d-555a9defd9922"
        },
        "4090fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4090fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4290fe0f-69fc-9ff9-699d-d9a9defd990d": {
            "Id": "4290fe0f-69fc-9ff9-699d-d9a9defd990d",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4390fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4390fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4290fe0f-69fc-9ff9-699d-d9a9defd990d"
        },
        "4590fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4590fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4290fe0f-69fc-9ff9-699d-d9a9defd990d"
        },
        "4690fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4690fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4290fe0f-69fc-9ff9-699d-d9a9defd990d"
        },
        "4790fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4790fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4290fe0f-69fc-9ff9-699d-d9a9defd990d"
        },
        "4890fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4890fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4891fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4891fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4892fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4892fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4893fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4893fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4894fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4894fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": true,
            "Modifie": true,
            "Visible": true,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4895fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4895fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": true,
            "Modifie": true,
            "Visible": true,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "4896fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "4896fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": true,
            "Modifie": true,
            "Visible": true,
            "IdParent": "4890fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-203b-d9a9defd990c": {
            "Id": "5090fe0f-69fc-9ff9-203b-d9a9defd990c",
            "Consult": true,
            "Modifie": true,
            "Visible": true,
            "IdParent": "5090fe0f-69fc-9ff9-203b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-213b-d9a9defd970c": {
            "Id": "5090fe0f-69fc-9ff9-213b-d9a9defd970c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-213b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-213b-d9a9defd971c": {
            "Id": "5090fe0f-69fc-9ff9-213b-d9a9defd971c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-213b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-213b-d9a9defd972c": {
            "Id": "5090fe0f-69fc-9ff9-213b-d9a9defd972c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-213b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-213b-d9a9defd990c": {
            "Id": "5090fe0f-69fc-9ff9-213b-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-203b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-214b-d9a9defd960c": {
            "Id": "5090fe0f-69fc-9ff9-214b-d9a9defd960c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-214b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-214b-d9a9defd990c": {
            "Id": "5090fe0f-69fc-9ff9-214b-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-203b-d9a9defd990c"
        },
        "5090fe0f-69fc-9ff9-699d-d9a9defd980a": {
            "Id": "5090fe0f-69fc-9ff9-699d-d9a9defd980a",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd980a"
        },
        "5090fe0f-69fc-9ff9-699d-d9a9defd990c": {
            "Id": "5090fe0f-69fc-9ff9-699d-d9a9defd990c",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd11aa": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd980a"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd11ab": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd11ab",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd11ac": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd11ac",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11ab"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd11ad": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd11ad",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd11ab"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd12aa": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd12aa",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd980a"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ab": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd12ab",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd12aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ac": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd12ac",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd12aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd12ad": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd12ad",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd12aa"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98aa": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98aa",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd980a"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98ba": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd980a"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bb": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bb",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bc": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bc",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bd": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bd",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98be": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98be",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bf": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bf",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bg": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bg",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bh": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bh",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bi": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bi",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bj": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bj",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bk": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bk",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bl": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bl",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bm": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bm",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd98bn": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd98bn",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5590fe0f-69fc-9ff9-699d-d9a9defd98ba"
        },
        "5590fe0f-69fc-9ff9-699d-d9a9defd990d": {
            "Id": "5590fe0f-69fc-9ff9-699d-d9a9defd990d",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "6e38797f-42a4-421a-a857-819bd1267f3a": {
            "Id": "6e38797f-42a4-421a-a857-819bd1267f3a",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "78201ecc-a821-496d-a18f-dbc5f4ef1ad5"
        },
        "7590fe0f-69fc-9ff9-699d-d9a9defd9922": {
            "Id": "7590fe0f-69fc-9ff9-699d-d9a9defd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "78201ecc-a821-496d-a18f-dbc5f4ef1ad5": {
            "Id": "78201ecc-a821-496d-a18f-dbc5f4ef1ad5",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "78201ecc-a821-496d-a18f-dbc5f4ef1ad5"
        },
        "adaf62c7-9ed2-4a18-be2f-95574f603510": {
            "Id": "adaf62c7-9ed2-4a18-be2f-95574f603510",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "6e38797f-42a4-421a-a857-819bd1267f3a"
        },
        "fbde9827-e270-49d8-852a-6734ddd7618b": {
            "Id": "fbde9827-e270-49d8-852a-6734ddd7618b",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "78201ecc-a821-496d-a18f-dbc5f4ef1ad5"
        },
        "7350fe0f-69fc-9ff9-699d-d9a9defd77922": {
            "Id": "7350fe0f-69fc-9ff9-699d-d9a9defd77922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "7550fe0f-69fc-9ff9-699d-d9a9def875872": {
            "Id": "7550fe0f-69fc-9ff9-699d-d9a9def875872",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "7550fe0f-69fc-9ff9-699d-d9a9def87ff72": {
            "Id": "7550fe0f-69fc-9ff9-699d-d9a9def87ff72",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7594fe0f-69fc-9ff9-699ddddddefd9922"
        },
        "7550fe0f-69fc-9ff9-699d-d9a9defd77922": {
            "Id": "7550fe0f-69fc-9ff9-699d-d9a9defd77922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "7550fe0f-69fc-9ff9-699d-d9a9defd77988": {
            "Id": "7550fe0f-69fc-9ff9-699d-d9a9defd77988",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "7590fe0f-69fc-9ff9-699d-555a9defd9922": {
            "Id": "7590fe0f-69fc-9ff9-699d-555a9defd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "5090fe0f-69fc-9ff9-699d-d9a9defd990c"
        },
        "7591fe0f-69fc-9ff9-699d-555a9defd9922": {
            "Id": "7591fe0f-69fc-9ff9-699d-555a9defd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7590fe0f-69fc-9ff9-699d-555a9defd9922"
        },
        "7592fe0f-69fc-9ff9-699d-555a9defd9922": {
            "Id": "7592fe0f-69fc-9ff9-699d-555a9defd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7590fe0f-69fc-9ff9-699d-555a9defd9922"
        },
        "8550fe0f-69fc-9ff9-699d-d9a9defd77988": {
            "Id": "8550fe0f-69fc-9ff9-699d-d9a9defd77988",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "8660fe0f-69fc-9ff9-699d-d9a9defd77987": {
            "Id": "8660fe0f-69fc-9ff9-699d-d9a9defd77987",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "8860fe0f-69fc-9ff9-699d-d9a9defd77983": {
            "Id": "8860fe0f-69fc-9ff9-699d-d9a9defd77983",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7550fe0f-69fc-9ff9-699d-d9a9defd77922"
        },
        "7550fe0f-69fc-9ff9-699d-d9a9defd7792ff": {
            "Id": "7550fe0f-69fc-9ff9-699d-d9a9defd7792ff",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7594fe0f-69fc-9ff9-699ddddddefd9922"
        },
        "7593fe0f-69fc-9ff9-699d-9696a9defd9922": {
            "Id": "7593fe0f-69fc-9ff9-699d-9696a9defd9922",
            "Consult": false,
            "Modifie": false,
            "Visible": false,
            "IdParent": "7590fe0f-69fc-9ff9-699d-555a9defd9922"
        }
    }
}


export const ProfileAuthorizationSlice = createSlice({
    name: "Profile Athorization",
    initialState,
    reducers: {
        setProfileAuthorization: (state, action) => {
            state.data = action.payload

        },

    }
})


export const { setProfileAuthorization } = ProfileAuthorizationSlice.actions;

export default ProfileAuthorizationSlice.reducer;