import { DocumentIcon, VerticalDotsIcon } from "@aureskonnect/react-ui";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { USER_ARCHIVED_ACCESS_ATHORIZATION_ID } from "@constants/index";

type ArchivedActionColumnType = {
    e: any;
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmUnArchivedModal: boolean;
    handleHistorysOnClickEvent: Function;
};

function ArchivedActionColumn({
    e,
    isConfirmUnArchivedModal,
    setIsConfirmUnArchivedModal,
    handleHistorysOnClickEvent,
}: ArchivedActionColumnType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    role="button"
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "cmn_btn_historical dropdown-item pl-1 pointer__clz"
                    )} ${
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    } `}
                    onClick={() => {
                        handleHistorysOnClickEvent(e.selectedRow);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                <div
                    role="button"
                    // eslint-disable-next-line jsx-a11y/tabindex-no-positive
                    tabIndex={1}
                    className={`car_btn_unarchiveAccount dropdown-item pl-1 ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        dynamicTableWrapperContext?.setRowData({
                            ...e.selectedRow,
                        });
                        setIsConfirmUnArchivedModal(!isConfirmUnArchivedModal);
                    }}
                >
                    <UnArchiveIcon width={25} height={25} />
                    <span className="ml-2">{t("UnArchiver account")}</span>
                </div>
            </div>
        </div>
    );
}

export default ArchivedActionColumn;
