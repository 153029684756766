import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DominantColor, DynamicTableWrapperContext } from "@components/Common/";

type ConfirmAccessModificationPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleSaveButtonOnClickEvent: Function;
    handleCloseModal: Function;
    setlistSelectedData: Function;
    setEditActionProfile?: Function;
};

export default function ConfirmPermissionModification({
    isOpen,
    setIsOpen,
    handleSaveButtonOnClickEvent,
    handleCloseModal,
    setlistSelectedData,
    setEditActionProfile,
}: ConfirmAccessModificationPropsType) {
    const { t } = useTranslation();

    const dominantColor: string = DominantColor();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader
                toggle={() => {
                    setIsOpen(false);
                    handleCloseModal();
                    setlistSelectedData({});
                    setEditActionProfile?.(false);
                    dynamicTableWrapperContext?.setDataUpdated(true);
                    dynamicTableWrapperContext?.setCheckProfileAuthModelModification(
                        false
                    );
                    dynamicTableWrapperContext?.setMultiAuthorizationProfileData(
                        {}
                    );
                }}
            >
                <span className="text-uppercase">{t("Confirmation")}</span>
            </ModalHeader>
            <ModalBody>
                {t("Do you want to confirm the changes made ?")}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    onClick={() => {
                        setIsOpen(false);
                        handleCloseModal();
                        setlistSelectedData({});
                        setEditActionProfile?.(false);
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setCheckProfileAuthModelModification(
                            false
                        );
                        dynamicTableWrapperContext?.setMultiAuthorizationProfileData(
                            {}
                        );
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    onClick={() => {
                        setIsOpen(false);
                        handleSaveButtonOnClickEvent();
                        dynamicTableWrapperContext?.setMultiAuthorizationProfileData(
                            {}
                        );
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
