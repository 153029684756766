import React from "react";
import { Row, CardBody, Card, Container } from "reactstrap";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    CheckboxIcon,
    StyledButton,
    StyledLabel,
    StyledTextInput,
} from "@aureskonnect/react-ui";
import { mutate } from "swr";
import { toast } from "react-toastify";

import NProgressWrapper from "@components/Common/NProgressWrapper";
import PageTitle from "@components/Common/PageTitle";
import ShowPasswordSvgIcon from "@components/Common/SvgIcons/ShowPasswordSvgIcon";
import HidePasswordSvgIcon from "@components/Common/SvgIcons/HidePasswordSvgIcon";
import PortalLogoSvgIcon from "@components/Common/SvgIcons/PortalLogoSvgIcon";

import { setProfileAuthorization } from "@store/features/ProfileAuthorizationSlice";

import { decryptWord } from "../Crypto";

import "./index.css";

export default function UpdatePassword() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const email = localStorage.getItem("email");

    const [inputPwd, setInputPwd] = React.useState<string>("");
    const [loading, setLoading] = React.useState<boolean>(false);
    const [messagePwdError, setMessagePwdError] = React.useState<string>(
        // eslint-disable-next-line sonarjs/no-duplicate-string
        t("This field is required")
    );
    const [isInvalidPassword, setIsInvalidPassword] =
        React.useState<boolean>(false);
    const [isPasswordNotConfirmed, setIsPasswordNotConfirmed] =
        React.useState<boolean>(false);
    const [isPasswordSame, setIsPasswordSame] = React.useState<boolean>(false);
    // eslint-disable-next-line
    const [isActiveUserModalOpned, setIsActiveUserModalOpned] =
        React.useState<boolean>(false);
    // eslint-disable-next-line
    const [msgTitleModal, setMsgTitleModal] = React.useState<string>("");

    // eslint-disable-next-line
    const [messageError, setMessageError] = React.useState<string>(
        t("This field is required")
    );

    const [isPasswordDisplayed, setIsPasswordDisplayed] =
        React.useState<boolean>(false);

    const [, setIsFocusInInputs] = React.useState<boolean>(false);

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleValidSubmit({ values }: any) {
        setIsFocusInInputs(false);
        setIsPasswordSame(false);
        setLoading(true);
        const urlVerifyUserAccount: string = `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}customerAccount`;

        const { email } = values;
        const passWord = values.password;
        const { confirmPassword } = values;
        const { newPassword } = values;

        if (passWord === newPassword) {
            setIsFocusInInputs(false);
            setLoading(false);
            setIsPasswordSame(true);
        } else if (confirmPassword !== newPassword) {
            setIsFocusInInputs(false);
            setLoading(false);
            setIsPasswordNotConfirmed(true);
        } else {
            await fetch(urlVerifyUserAccount, {
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                },
                body: `email=${email}&password=${passWord}`,
                method: "POST",
            })
                .then((result) => result.json())
                // eslint-disable-next-line consistent-return
                .then(async (response: any) => {
                    if (response.error) {
                        const decryptEmail = decryptWord(response.data.email);
                        const decryptPwd = decryptWord(response.data.pwd);
                        if (decryptEmail === "" && decryptPwd === "") {
                            setMessageError(
                                t(
                                    "Account not found. Verify your email address."
                                )
                            );
                        } else if (
                            email === decryptEmail &&
                            passWord !== decryptPwd
                        ) {
                            setIsInvalidPassword(true);
                            setInputPwd("");
                        } else if (
                            email !== decryptEmail &&
                            passWord === decryptPwd
                            // eslint-disable-next-line sonarjs/no-duplicated-branches
                        ) {
                            setMessageError(
                                t(
                                    "Account not found. Verify your email address."
                                )
                            );
                        }
                    } else if (response.data.email !== "" && !response.error) {
                        if (response.data.etataccount === "Activer") {
                            const url = `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}auth/generate-password`;

                            try {
                                mutate(
                                    url,
                                    await fetch(url, {
                                        headers: {
                                            "Content-Type": "application/json",
                                            authorization: `Bareer ${localStorage.getItem(
                                                "jwt"
                                            )}`,
                                        },
                                        body: JSON.stringify({
                                            password: values.password,
                                            email: values.email,
                                            newPassword: values.newPassword,
                                        }),
                                        method: "PUT",
                                    })
                                        .then((response) => response.json())
                                        .then(async (data) => {
                                            if (data.error) {
                                                throw Error("Error!");
                                            }

                                            toast.success(
                                                "Password updated successfully!",
                                                {
                                                    position:
                                                        toast.POSITION
                                                            .TOP_CENTER,
                                                    autoClose: 2000,
                                                    theme: "colored",
                                                    closeOnClick: true,
                                                    pauseOnHover: true,
                                                    draggable: true,
                                                    icon: (
                                                        <CheckboxIcon
                                                            height={25}
                                                            width={25}
                                                            fill="white"
                                                        />
                                                    ),
                                                }
                                            );

                                            localStorage.setItem(
                                                "email",
                                                email
                                            );

                                            localStorage.setItem(
                                                "Uid_Entitie_User",
                                                response.data.uidentitie
                                            );
                                            localStorage.setItem(
                                                "firstName",
                                                response.data.firstname
                                            );
                                            localStorage.setItem(
                                                "lastName",
                                                response.data.name
                                            );
                                            localStorage.setItem(
                                                "Uid_User",
                                                response.data.uuiduser
                                            );
                                            setLoading(false);

                                            const getCustomerListURL =
                                                await fetch(
                                                    `${process.env.REACT_APP_CONSOLE_ADMIN_API_URL}/accounts/${response.data.uidentitie}/${response.data.uuiduser}`
                                                );
                                            fetch(
                                                `${process.env.REACT_APP_ACCESS_API_URL}/used-profile-autorisation?uidUser='${response.data.uuiduser}'`,
                                                {
                                                    method: "get",
                                                    headers: {
                                                        "Content-Type":
                                                            "application/json",
                                                    },
                                                }
                                            ).then((response) => {
                                                response.json().then((data) => {
                                                    dispatch(
                                                        setProfileAuthorization(
                                                            data.authorization
                                                        )
                                                    );
                                                });
                                            });

                                            const customerList =
                                                await getCustomerListURL.json();

                                            let uuids: string = "";
                                            for (const uid of customerList.data) {
                                                uuids += `,'${uid.uid}'`;
                                            }

                                            localStorage.setItem(
                                                "uuids",
                                                `"${uuids.substring(1)}"`
                                            );

                                            localStorage.setItem(
                                                "NumberOfAccount",
                                                customerList.data.length
                                            );
                                            // eslint-disable-next-line sonarjs/no-all-duplicated-branches
                                            if (customerList.data.length > 1) {
                                                navigate("/customer-accounts");
                                            } else {
                                                localStorage.setItem(
                                                    "customerAccountId",
                                                    customerList.data[0].uid
                                                );

                                                localStorage.setItem(
                                                    "TypeAccount",
                                                    customerList.data[0][
                                                        "Type du compte"
                                                    ]
                                                );

                                                localStorage.setItem(
                                                    "customerAccountName",
                                                    customerList.data[0][
                                                        "Nom commercial"
                                                    ]
                                                );

                                                const selectedCustomerCodeUrl =
                                                    await fetch(
                                                        `${process.env.REACT_APP_SETTING_API_URL}settings/pays`
                                                    );

                                                const countryCode: any =
                                                    await selectedCustomerCodeUrl.json();
                                                if (
                                                    countryCode.data !==
                                                    undefined
                                                ) {
                                                    const selectedCountryCode =
                                                        countryCode.data.find(
                                                            (data: any) =>
                                                                data.Designation ===
                                                                customerList
                                                                    .data[0]
                                                                    .Pays
                                                        );

                                                    localStorage.setItem(
                                                        "selectedCountryCode",
                                                        selectedCountryCode.code.toLowerCase()
                                                    );
                                                } else {
                                                    localStorage.setItem(
                                                        "selectedCountryCode",
                                                        "fr"
                                                    );
                                                }
                                                navigate("/users-accounts");
                                            }
                                        })
                                );
                            } catch (e: any) {
                                toast.error(`${t("There's an error !")!}`, {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                });
                            }
                        } else {
                            setIsActiveUserModalOpned(true);

                            if (response.data.accounttype === "ADMIN") {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter notre service support."
                                );
                            } else {
                                setMsgTitleModal(
                                    "Vous n'êtes plus autorisé à utiliser ce compte. Veuillez contacter votre administrateur."
                                );
                            }
                        }
                    } else {
                        return toast.error(`${t("There is an error")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }

                    setLoading(false);
                })
                .catch(() => {
                    setLoading(false);
                    return false;
                });
        }
    }

    React.useEffect(() => {
        if (inputPwd.length === 0 && !isInvalidPassword) {
            setMessagePwdError(t("This field is required"));
        } else {
            setMessagePwdError(t("the current password is not correct"));
            setIsInvalidPassword(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inputPwd]);

    return (
        <React.Fragment>
            <PageTitle title={t("Change password")} />
            <video
                autoPlay
                loop
                muted
                style={{
                    zIndex: 0,
                }}
                className="video position-fixed float-right"
            >
                <source src="background.mp4" type="video/mp4" />
            </video>
            <Container fluid className="p-0 m-0 overflow-hidden">
                <NProgressWrapper isLoading={loading} />
                <Row>
                    <div
                        className="white-section"
                        style={{
                            height: "100vh",
                            backgroundColor: "white",
                        }}
                    >
                        <div className="d-flex justify-content-center align-items-center">
                            <PortalLogoSvgIcon
                                fill="black"
                                className="etk-logo__clz"
                            />
                        </div>
                    </div>
                    <Card
                        className="overflow-hidden update-card__clz w-sm-80 w-md-60 w-lg-50 w-xl-40 w-xxl-30"
                        style={{
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            boxShadow: "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            WebkitBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            MozBoxShadow:
                                "rgba(0, 0, 0, 0.42) -5px 3px 12px -2px",
                            borderRadius: "2%",
                            position: "absolute",
                            transform: "translate(-50%, -50%)",
                            padding: "10px",
                            margin: "0px",
                        }}
                    >
                        <p
                            className="mb-2 mt-4 ml-4"
                            style={{
                                fontSize: "33px",
                                fontWeight: 500,
                            }}
                        >
                            {t("Change password")}
                        </p>
                        <CardBody className="ml-1 py-0">
                            <div>
                                <AvForm
                                    className="form-horizontal"
                                    onValidSubmit={(e: any, v: any) => {
                                        handleValidSubmit({ values: v });
                                    }}
                                >
                                    <div className="mb-3">
                                        <StyledLabel htmlFor="email">
                                            Email
                                        </StyledLabel>
                                        <StyledTextInput
                                            className="form-control__clz not-allowed-icon__clz"
                                            id="email"
                                            name="email"
                                            type="email"
                                            value={email}
                                        />
                                    </div>
                                    <div className="password-toggle__clz">
                                        {isPasswordDisplayed ? (
                                            <HidePasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        ) : (
                                            <ShowPasswordSvgIcon
                                                onClick={() =>
                                                    setIsPasswordDisplayed(
                                                        (prevState: boolean) =>
                                                            !prevState
                                                    )
                                                }
                                            />
                                        )}
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Current password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="password"
                                            name="password"
                                            value={inputPwd}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage:
                                                        messagePwdError,
                                                },
                                            }}
                                            onChange={(e: any) => {
                                                setInputPwd(e.target.value);
                                            }}
                                            placeholder={t("Current password")}
                                            className="form-control__clz"
                                        />
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="password">
                                            {t("Nouveau mot de passe")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="newPassword"
                                            name="newPassword"
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Nouveau mot de passe"
                                            )}
                                            className="form-control__clz"
                                        />

                                        {isPasswordSame ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "New password cannot be same the current password !"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="mb-3">
                                        <StyledLabel htmlFor="confirmPassword">
                                            {t("Confirm the new password")}
                                        </StyledLabel>
                                        <StyledTextInput
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            onChange={() => {
                                                setIsPasswordNotConfirmed(
                                                    false
                                                );
                                            }}
                                            type={
                                                isPasswordDisplayed
                                                    ? "text"
                                                    : "password"
                                            }
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: t(
                                                        "This field is required"
                                                    ),
                                                },
                                            }}
                                            placeholder={t(
                                                "Confirm the new password"
                                            )}
                                            className="form-control__clz"
                                        />
                                        {isPasswordNotConfirmed ? (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    marginTop: "0.25rem",
                                                    fontSize: "80%",
                                                    color: "#f46a6a",
                                                }}
                                            >
                                                {t(
                                                    "Sorry, your passwords not confirmed"
                                                )}
                                            </div>
                                        ) : null}
                                    </div>

                                    <div className="d-flex justify-content-center submit-button__clz">
                                        <StyledButton
                                            variant="primary"
                                            rounded
                                            outline={false}
                                            className="text-uppercase"
                                            type="submit"
                                        >
                                            {t("Connection")}
                                        </StyledButton>
                                    </div>
                                </AvForm>
                            </div>
                        </CardBody>
                    </Card>
                </Row>
            </Container>
        </React.Fragment>
    );
}
