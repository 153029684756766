import { createSlice } from "@reduxjs/toolkit";

type storeType = {
    access: { id: string; authorization: string }[];
    updatedData: { isAccessDataUpdated: boolean; value: boolean };
    isEmailModifConfirmed: boolean;
};

const initialState: storeType = {
    access: [{ id: "", authorization: "" }],
    updatedData: { isAccessDataUpdated: false, value: false },
    isEmailModifConfirmed: false,
};

export const accessSlice = createSlice({
    name: "accessSlice",
    initialState,
    reducers: {
        setUserChildsAccess: (state: any, action: any) => {
            state.access = action.payload;
        },

        updateUserChildsAccess: (state: any, action: any) => {
            const { userAccessStatus, entityChildId } = action.payload;
            if (state.access.length > 0) {
                const newAccess = state.access.map((item: any) => {
                    if (item.id === entityChildId) {
                        return {
                            ...item,
                            authorization: userAccessStatus,
                        };
                    }
                    return item;
                });

                state.access = newAccess;
            }
        },
        updateIsAccessDataUpdated(state: any, action: any) {
            state.updatedData = action.payload;
        },
        setEmailModif(state: any, action: any) {
            state.isEmailModifConfirmed = action.payload;
        },
    },
});

export const {
    updateUserChildsAccess,
    setUserChildsAccess,
    updateIsAccessDataUpdated,
    setEmailModif,
} = accessSlice.actions;

export default accessSlice.reducer;
