import {
    DocumentIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { UnlockIcon } from "@components/Common/SvgIcons/UnlockIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { USER_BLOCKED_ACCESS_ATHORIZATION_ID } from "@constants/index";

type BlocMultiActionColumnType = {
    selectedRows: any[];
    setIsConfirmBlockModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleHistorysOnClickEvent: Function;
};

function BlocMultiActionColumn({
    selectedRows,
    setIsConfirmBlockModal,
    setIsConfirmArchivedModal,
    handleHistorysOnClickEvent,
}: BlocMultiActionColumnType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    function handleMultiUnblockAction(selectedRows: any[]) {
        setIsConfirmBlockModal(true);
        dynamicTableWrapperContext?.setRowData(selectedRows);
    }

    function handleMultiArchiverAction(selectedRows: any[]) {
        setIsConfirmArchivedModal(true);
        dynamicTableWrapperContext?.setRowData(selectedRows);
    }

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* Display Account History */}
                <div
                    role="button"
                    tabIndex={-2}
                    className={`dropdown-item pl-1 cmn_btn_multipleHistorical ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleHistorysOnClickEvent(selectedRows);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                {/* UNBLOCK user account from Blocked List */}
                <div
                    role="button"
                    tabIndex={-1}
                    className={`cmn_btn_unlockAccounts d-flex  align-items-center  dropdown-item pl-1 ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiUnblockAction(selectedRows);
                    }}
                >
                    <UnlockIcon width={45} height={39} />
                    <span>{t("UnBlock account(s)")}</span>
                </div>
                {/* ARCHIVE User Account on Blocked User List */}
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item d-flex  align-items-center pl-1 cmn_btn_archivedAccounts ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiArchiverAction(selectedRows);
                    }}
                >
                    <TrashIcon
                        width={45}
                        height={26}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span>{t("Archive account(s)")}</span>
                </div>
            </div>
        </div>
    );
}
export default BlocMultiActionColumn;
