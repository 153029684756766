import React from "react";
import { StyledTextInput, StyledLabel } from "@aureskonnect/react-ui";
import { AvGroup } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";

import { CustomCardBody } from "@components/Common/CustomCardStyled";
import "react-phone-input-2/lib/style.css";
import { RootState } from "@store/index";
import { useSelector } from "react-redux";

type FormBodyPropsType = {
    profile: ProfileType;
    setProfile: React.Dispatch<React.SetStateAction<ProfileType>>;
    // eslint-disable-next-line no-unused-vars
    addToRefs: (el: any) => void;
    isDesignationExist: boolean;
};
export function FormBody({
    profile,
    setProfile,
    addToRefs,
    isDesignationExist,
}: FormBodyPropsType) {
    const { t } = useTranslation();
    const isProfileListArchived = useSelector(
        (state: RootState) => state.profileSlice.isProfileListArchived
    );

    return (
        <CustomCardBody
            istablet="true"
            isvisible="true"
            className="card-body__clz"
        >
            <AvGroup>
                <StyledLabel
                    htmlFor="Désignation"
                    className="required__clz mt-3"
                >
                    {t("Désignation")}
                </StyledLabel>
                <StyledTextInput
                    className="pfl_inp_designation"
                    autoComplete="off"
                    id="Désignation"
                    name="Désignation"
                    placeholder={t("To input")}
                    type="text"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setProfile({
                            ...profile,
                            Désignation: event.target.value.trim(),
                        });
                    }}
                    value={profile.Désignation}
                    autoFocus
                    validate={{
                        required: {
                            value: profile.Désignation === "",
                            errorMessage: t("This field is required"),
                        },
                    }}
                    innerRef={addToRefs}
                    disabled={!!isProfileListArchived}
                />
                {isDesignationExist ? (
                    <div
                        style={{
                            width: "100%",
                            marginTop: "0.25rem",
                            fontSize: "80%",
                            color: "#f46a6a",
                        }}
                    >
                        {t("The ''Désignation''is already existed")!}
                    </div>
                ) : null}
            </AvGroup>
            {/* <AvGroup>
                <StyledLabel htmlFor="text" className=" mt-3">
                    {t("Tag")}
                </StyledLabel>
                <Input
                    className="pfl_inp_tag"
                    placeholder={t("Select")}
                    name="Balise"
                    nooptionsmessage={t("No tags")}
                    onChange={(e: any) => {
                        setProfile({
                            ...profile,
                            Balise: e.target.value,
                        });
                    }}
                    value={profile.Balise}
                    disabled={!!isProfileListArchived}
                />
            </AvGroup> */}
            <AvGroup>
                <StyledLabel htmlFor="text" className=" mt-3">
                    {t("Description")}
                </StyledLabel>
                <StyledTextInput
                    className="pfl_inp_description"
                    maxLength={2000}
                    autoComplete="off"
                    id="Description"
                    name="Description"
                    placeholder={t("To input")}
                    type="textarea"
                    onChange={(e: any) => {
                        setProfile({
                            ...profile,
                            Description: e.target.value,
                        });
                    }}
                    value={profile.Description}
                    disabled={!!isProfileListArchived}
                />
            </AvGroup>
        </CustomCardBody>
    );
}
