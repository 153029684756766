import React from "react";

type PropsType = {
    width?: number;
    height?: number;
};

export function UserIcon({ width, height }: PropsType): JSX.Element {
    return (
        <svg
            id="user_3_"
            data-name="user (3)"
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 26.463 26.463"
        >
            <g id="Groupe_14048" data-name="Groupe 14048">
                <path
                    id="Tracé_9857"
                    data-name="Tracé 9857"
                    d="M12.151,24.258H.551a.552.552,0,0,1-.536-.685l.6-2.393a3.531,3.531,0,0,1,2.325-2.507L7.367,17.2c.657-.275,1.067-1.265,1.109-2.725a4.378,4.378,0,0,1-1.6-2.739c0-.4-.136-.487-.137-.488a.458.458,0,0,1-.321-.311,7.546,7.546,0,0,1-.535-2.2A.528.528,0,0,1,5.9,8.6a1.281,1.281,0,0,1,.49-.746c.007-.132.014-.279.023-.442.028-.545.066-1.271.066-2.175C6.483,3.169,9.013,0,12.129,0c2.819,0,4.184,1.5,4.41,2.472.689.382,1.29,1.011,1.29,2.765V7.858a1.451,1.451,0,0,1,.513.7.555.555,0,0,1,.028.175.571.571,0,0,1-.551.573.535.535,0,0,1-.545-.447.336.336,0,0,0-.065-.09.482.482,0,0,1-.483-.533v-3c0-1.439-.462-1.672-.738-1.809a.779.779,0,0,1-.518-.683c-.02-.332-.948-1.643-3.341-1.643A4.947,4.947,0,0,0,7.586,5.237c0,.928-.039,1.674-.069,2.233-.015.319-.03.576-.03.766a.552.552,0,0,1-.5.549,6.306,6.306,0,0,0,.388,1.557,1.612,1.612,0,0,1,.6,1.389,3.582,3.582,0,0,0,1.4,2.067.552.552,0,0,1,.2.425c0,2.093-.649,3.515-1.829,4L3.284,19.72a2.434,2.434,0,0,0-1.6,1.729l-.423,1.706H12.151a.551.551,0,1,1,0,1.1Z"
                    transform="translate(0.001)"
                    fill="#fff"
                />
            </g>
            <g
                id="Groupe_14049"
                data-name="Groupe 14049"
                transform="translate(13.232 11.026)"
            >
                <path
                    id="Tracé_9858"
                    data-name="Tracé 9858"
                    d="M18.616,25.437a.535.535,0,0,1-.214-.044c-.262-.109-6.4-2.763-6.4-8.186V12.6a.553.553,0,0,1,.375-.523l6.064-2.048a.552.552,0,0,1,.353,0l6.064,2.048a.551.551,0,0,1,.375.523v4.608c0,5.423-6.139,8.077-6.4,8.187a.553.553,0,0,1-.214.043ZM13.1,12.995v4.212c0,4.173,4.471,6.569,5.513,7.072,1.041-.5,5.513-2.909,5.513-7.072V12.995l-5.513-1.861Z"
                    transform="translate(-12 -10)"
                    fill="#fff"
                />
            </g>
            <g
                id="Groupe_14050"
                data-name="Groupe 14050"
                transform="translate(16.541 15.988)"
            >
                <path
                    id="Tracé_9859"
                    data-name="Tracé 9859"
                    d="M17.756,19.462h-.03a.551.551,0,0,1-.4-.206L15.121,16.5a.551.551,0,0,1,.861-.689L17.8,18.085l3.424-3.424a.551.551,0,1,1,.78.78L18.146,19.3A.545.545,0,0,1,17.756,19.462Z"
                    transform="translate(-15 -14.5)"
                    fill="#fff"
                />
            </g>
        </svg>
    );
}
