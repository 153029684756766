import { VerticalDotsIcon } from '@aureskonnect/react-ui';
import React from 'react'
import { useTranslation } from 'react-i18next';

type AuthorizationMultipleProfileType = {
    selectedRows: any[];
    setAuthMultiprofileModeOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function AuthorizationMultipleProfile({
    selectedRows,
    setAuthMultiprofileModeOpen,
}: AuthorizationMultipleProfileType) {
    const { t } = useTranslation();

    return (
        <div className=" dropdown d-flex justify-content-end align-items-end">
            <VerticalDotsIcon
                className="cmn_drp_menu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />

            <div className="dropdown-menu">
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item pl-1 pfl_btn_activatedProfile ${
                        (selectedRows as any[]).length > 0 ? "" : "disabled_clz"
                    }`}
                    onClick={() => setAuthMultiprofileModeOpen(true)}
                >
                    <span className="ml-2">
                        {t("Bundled authorization management")}
                    </span>
                </div>
            </div>
        </div>
    );
}
