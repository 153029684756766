import React from "react";
import {
    PlusIcon,
    StyledButtonWithIcon,
    StyledIconButton,
} from "@aureskonnect/react-ui";
import { USER_ACCOUNT_ACCESS_ATHORIZATION_ID } from "@constants/index";
import { DominantColor, DynamicTableWrapperContext } from "@components/Common";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { ActionMultiUserAccount } from "./ActionMultiUserAccount";


type ActionCustonFiltertype = {
    handleSubmit: Function;
    selectedRows: any[];
    handleManageAccessOnClickEvent: Function | undefined;
    handleBlockAccountOnClickEvent: Function;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmArchivedModal: boolean;
    handleHistorysOnClickEvent: Function;
};
function ActionCustonFilter({
    handleSubmit,
    selectedRows,
    handleManageAccessOnClickEvent,
    handleBlockAccountOnClickEvent,
    setIsConfirmArchivedModal,
    isConfirmArchivedModal,
    handleHistorysOnClickEvent,
}: ActionCustonFiltertype) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );


    return (
        <div className="d-flex flex-row align-items-center ml-2">
            <AvForm onSubmit={handleSubmit}>
                {!dynamicTableWrapperContext?.isActionColumnDisplayed ? (
                    <div className="cmn_btn_openForm">
                        <StyledButtonWithIcon
                            icon="PlusIcon"
                            iconPosition="left"
                            rounded
                            variant={dominantColor}
                            disabled={
                                authorization[
                                    USER_ACCOUNT_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                !authorization[
                                    USER_ACCOUNT_ACCESS_ATHORIZATION_ID
                                ].Modifie
                            }
                        >
                            {t("Add user account")}
                        </StyledButtonWithIcon>
                    </div>
                ) : (
                    <StyledIconButton
                        style={{
                            width: "48px",
                            height: "48px",
                        }}
                        variant={dominantColor}
                        icon="PlusIcon"
                        className="bg-dark ctl_btn_closeForm"
                        rounded
                        disabled={false}
                    >
                        <PlusIcon height={20} width={20} fill="white" />
                    </StyledIconButton>
                )}
            </AvForm>
            <div className="d-flex justify-content-end align-items-end">
                <ActionMultiUserAccount
                    selectedRows={selectedRows}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleManageAccessOnClickEvent={
                        handleManageAccessOnClickEvent
                    }
                    // eslint-disable-next-line react/jsx-no-bind
                    handleBlockAccountOnClickEvent={
                        handleBlockAccountOnClickEvent
                    }
                    setIsConfirmArchivedModal={setIsConfirmArchivedModal}
                    isConfirmArchivedModal={isConfirmArchivedModal}
                    handleHistorysOnClickEvent={handleHistorysOnClickEvent}
                />
            </div>
        </div>
    );
}

export default ActionCustonFilter;
