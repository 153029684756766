import React from "react";

type PropsType = {
    width?: number;
    height?: number;
};

export function UnArchiveIcon({ width, height }: PropsType): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 18.575 17.026"
        >
            <path
                id="telechargement-de-dossier"
                d="M14.705,2.548H9.653a.776.776,0,0,1-.346-.081l-2.44-1.22A2.331,2.331,0,0,0,5.826,1H3.87A3.873,3.873,0,0,0,0,4.87v9.288a3.874,3.874,0,0,0,3.87,3.87H6.192a.774.774,0,1,0,0-1.548H3.87a2.325,2.325,0,0,1-2.322-2.322V7.192H17.027v6.966a2.325,2.325,0,0,1-2.322,2.322H12.383a.774.774,0,1,0,0,1.548h2.322a3.874,3.874,0,0,0,3.87-3.87V6.418A3.874,3.874,0,0,0,14.705,2.548ZM1.548,4.87A2.325,2.325,0,0,1,3.87,2.548H5.826a.771.771,0,0,1,.346.082L8.615,3.851A2.335,2.335,0,0,0,9.653,4.1h5.052a2.319,2.319,0,0,1,2.179,1.548H1.548V4.869Zm9.514,7.659-1-1v5.725a.774.774,0,0,1-1.548,0V11.528l-1,1a.774.774,0,0,1-1.094-1.094l1.248-1.248a2.347,2.347,0,0,1,3.241,0l1.248,1.248a.774.774,0,0,1-1.094,1.094Z"
                transform="translate(0 -1.001)"
            />
        </svg>
    );
}
