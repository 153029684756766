/* eslint-disable no-unused-vars */
import React from "react";
import { Row, Col } from "reactstrap";

type BreadcrumbPropsType = {
    title: string;
};

export default function Breadcrumb({ title }: BreadcrumbPropsType) {
    return (
        <Row style={{ margin: "15px" }}>
            <Col xs="12">
                <div className="page-title-box d-flex align-items-center justify-content-between">
                    <h4 className="mb-0 font-size-18">{title}</h4>
                    <div className="page-title-right" />
                </div>
            </Col>
        </Row>
    );
}
