import React from "react";
import { motion } from "framer-motion/dist/framer-motion";

import { StyledBreadcrumb } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import { DynamicTableWrapper, PageTitle } from "@components/Common";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";

import { USER_ARCHIVED_ACCESS_ATHORIZATION_ID } from "@constants/index";
import { ArchivedAccountList } from "./ArchivedAccountList";
import { ConsultUser } from "./ConsultUser";

export default function ArchivedAccounts(): JSX.Element {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                {authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                    undefined &&
                authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID].Consult ? (
                    <>
                        <StyledBreadcrumb
                            items={[
                                {
                                    item: "Access",
                                    link: "#",
                                },
                                {
                                    item: t(selectedCustomer),
                                    link: "#",
                                },
                                {
                                    item: t("User management"),
                                    link: "#",
                                },
                                {
                                    item: t("Archived accounts"),
                                    link: "#",
                                },
                            ]}
                        />
                        <Container fluid>
                            <HeaderComponent
                                title={t(
                                    "It is possible to consult the customer accounts archived in this section. Their activation is allowed ."
                                )}
                            />
                            <DynamicTableWrapper
                                TableComponent={<ArchivedAccountList />}
                                ActionColumnComponent={<ConsultUser />}
                            />
                        </Container>
                    </>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: "238px" }}
                    >
                        <img
                            alt="img"
                            src="./images/emptyAccounts.png"
                            style={{
                                width: "248px",
                                height: "248px",
                            }}
                        />
                        <span
                            style={{
                                width: "295px",
                                height: "40px",
                                textAlign: "left",
                                font: "normal normal 600 30px/40px Segoe UI",
                                color: "#495057",
                                marginLeft: "3rem",
                            }}
                        >
                            {t("No access allowed")}
                        </span>
                    </div>
                )}
            </motion.div>
        </React.Fragment>
    );
}
