import React from "react";

import { useTranslation } from "react-i18next";
import { StyledButton } from "@aureskonnect/react-ui";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import { DynamicTableWrapperContext, DominantColor } from "@components/Common/";

import { useDispatch } from "react-redux";
import { setEmailModif } from "@store/features/accessSlice";

type ConfirmModalPropsType = {
    isOpen: boolean;
    setIsOpen: Function;
    email: string;
};

export default function ConfirmMailModal({
    isOpen,
    setIsOpen,
    email,
}: ConfirmModalPropsType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const dispatch = useDispatch();

    const dominantColor: string = DominantColor();

    function handleConfirmEmailModifOnClickEvent(value: boolean) {
        dispatch(setEmailModif(value));
        setIsOpen(!isOpen);
    }

    return (
        <Modal centered isOpen={isOpen}>
            <ModalHeader toggle={(): boolean => setIsOpen(!isOpen)} tag="div">
                <span className="text-uppercase cmn_txt_titleAlert">
                    {t("Confirmation")}
                    {dynamicTableWrapperContext?.rowData?.title}
                </span>
            </ModalHeader>
            <ModalBody className="cmn_txt_descriptionAlert">
                {`${t(
                    "If you confirm the change of email address, the account owner will no longer be able to access with their old address."
                )} 
                ${t("An e-mail will be sent to the e-mail address")}
                "${email}"  ${t("with the new access data.")} `}
            </ModalBody>
            <ModalFooter className="border-top-0">
                <StyledButton
                    className="cmn_btn_cancelAlert"
                    onClick={() => {
                        handleConfirmEmailModifOnClickEvent(false);
                    }}
                    rounded
                    variant="light"
                    outline
                >
                    {t("Cancel")}
                </StyledButton>

                <StyledButton
                    className="cmn_btn_validateAlert"
                    onClick={() => {
                        handleConfirmEmailModifOnClickEvent(true);
                    }}
                    rounded
                    variant={dominantColor}
                >
                    {t("Validate")}
                </StyledButton>
            </ModalFooter>
        </Modal>
    );
}
