/* eslint-disable no-lonely-if */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from "react";
import { CheckboxIcon } from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import ConfirmArchivedModalProfile from "@components/Common/Modals/ConfirmArchivedModalProfile";
import ConfirmUnArchivedModalProfile from "@components/Common/Modals/ConfirmUnArchivedModalProfile";
import MangeProfilePermissions from "@components/Common/Modals/ManageProfilePermissions/MangeProfilePermissions";
import { DynamicTable } from "@maherunlocker/custom-react-table";

import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { AuthorisationProfileModel } from "@components/Common/Modals/AuthorisationsProfileModel";
import HistoryModal from "@components/Common/Modals/HistoryModal";

// eslint-disable-next-line import/no-duplicates
import "./style.css";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { APPLICATION_UID } from "@constants/index";
import ColumnActive from "./ColumnActive";
import CustomActionFilter from "./CustomActionFilter";
import CustomJsx from "./CustomJsx";

export function ProfileList(): JSX.Element {
    const { t } = useTranslation();
    const i18nextLng = localStorage.getItem("i18nextLng") as string;

    const isVisible = true;

    const headers = {
        "Content-Type": "application/json",
    };

    const [profileStatus, setProfileStatus] = React.useState("Activé");
    const [accountNumber, setAccountNumber] = React.useState<number>(0);
    const [listSelectedData, setlistSelectedData] = React.useState<any>({});
    const [editProfile, setEditActionProfile] = React.useState<boolean>(false);
    const customerAccountId = localStorage.getItem("customerAccountId");
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const [isManagePermissionsModalOpened, setIsManagePermissionsModalOpened] =
        React.useState<boolean>(false);
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const isProfileListArchived = useSelector(
        (state: RootState) => state.profileSlice.isProfileListArchived
    );

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const [isConfirmArchivedModal, setIsConfirmArchivedModal] =
        React.useState<boolean>(false);
    const [isConfirmUnArchivedModal, setIsConfirmUnArchivedModal] =
        React.useState<boolean>(false);

    const [isAuthorisationProfileAlert, setIsAuthorisationProfileAlert] =
        React.useState<boolean>(false);

    const [isHistoryModalOpened, setIsHistoryModalOpened] =
        React.useState<boolean>(false);
    function handleOnEditUser(profile: any) {
        dynamicTableWrapperContext?.setIsEdit(true);
        dynamicTableWrapperContext?.setRowData({ ...profile });
    }

    const handleSubmit = () => {
        dynamicTableWrapperContext?.setRowData({
            Désignation: "",
            Balise: "",
            Description: "",
        });

        dynamicTableWrapperContext?.setActionColumnDisplay(
            (isActionColumnDisplayed): boolean => !isActionColumnDisplayed
        );
        dynamicTableWrapperContext?.setLocalFilterActive(false);
    };

    function handleManagePermissionsOnClickEvent(selectedRow: any) {
        if (
            selectedRow?.original &&
            Object.keys(selectedRow?.original?.autorisation).length > 0
        ) {
            setIsManagePermissionsModalOpened(!isManagePermissionsModalOpened);
            dynamicTableWrapperContext?.setRowData({ ...selectedRow });
            setEditActionProfile(true);
        } else {
            dynamicTableWrapperContext?.setRowData({ ...selectedRow[0] });
            setEditActionProfile(true);
            setIsManagePermissionsModalOpened(true);
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleEditedProfileButtonOnClickEvent() {
        const dataProfiles: any = [];
        let profiles: any;
        let copyData: any;
        const dataProject: any = JSON.parse(
            localStorage.getItem("dataProject") as string
        );

        if (dynamicTableWrapperContext?.rowData?.original) {
            profiles = dynamicTableWrapperContext?.rowData?.original;
            copyData = profiles.autorisation;
            copyData = { ...dataProject, ...copyData };
        } else {
            profiles = dynamicTableWrapperContext?.rowData;
            copyData = profiles[0].autorisation;
            copyData = { ...dataProject, ...copyData };
        }

        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-profile?profileStatus=${profileStatus}`;
        Object.keys(copyData).forEach((el: any) => {
            Object.keys(listSelectedData).forEach((elData: any) => {
                if (el === elData) {
                    copyData[el] = listSelectedData[elData];
                }
            });
        });
        Object.keys(copyData).forEach((el: any) => {
            APPLICATION_UID.forEach((elData: any) => {
                if (el === elData) {
                    copyData[el].Visible = true;
                    copyData[el].Consult = true;
                }
            });
        });

        Object.values(copyData).forEach((Element: any) => {
            if (Element.IdParent === Element.Id) {
                if (copyData[Element.Id].Visible === true) {
                    if (copyData[Element.Id].Consult === false) {
                        copyData[Element.Id].Modifie = false;
                    }
                } else {
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                }
            } else {
                if (copyData[Element.IdParent].Visible === false) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === false
                    // eslint-disable-next-line sonarjs/no-duplicated-branches
                ) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === true
                ) {
                    if (copyData[Element.Id].Visible === false) {
                        copyData[Element.Id].Visible = false;
                        copyData[Element.Id].Consult = false;
                        copyData[Element.Id].Modifie = false;
                    } else if (
                        copyData[Element.Id].Visible === true &&
                        (copyData[Element.Id].Consult === false ||
                            copyData[Element.IdParent].Modifie === false)
                    ) {
                        copyData[Element.Id].Modifie = false;
                    }
                }
            }
        });
        Object.values(copyData).forEach((Element: any) => {
            if (Element.IdParent === Element.Id) {
                if (copyData[Element.Id].Visible === true) {
                    if (copyData[Element.Id].Consult === false) {
                        copyData[Element.Id].Modifie = false;
                    }
                } else {
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                }
            } else {
                if (copyData[Element.IdParent].Visible === false) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === false
                    // eslint-disable-next-line sonarjs/no-duplicated-branches
                ) {
                    copyData[Element.Id].Visible = false;
                    copyData[Element.Id].Consult = false;
                    copyData[Element.Id].Modifie = false;
                } else if (
                    copyData[Element.IdParent].Visible === true &&
                    copyData[Element.IdParent].Consult === true
                ) {
                    if (copyData[Element.Id].Visible === false) {
                        copyData[Element.Id].Visible = false;
                        copyData[Element.Id].Consult = false;
                        copyData[Element.Id].Modifie = false;
                    } else if (
                        copyData[Element.Id].Visible === true &&
                        (copyData[Element.Id].Consult === false ||
                            copyData[Element.IdParent].Modifie === false)
                    ) {
                        copyData[Element.Id].Modifie = false;
                    }
                }
            }
        });
        // check fo simple or multiple profile and set authorization
        if (dynamicTableWrapperContext?.rowData?.original) {
            profiles = dynamicTableWrapperContext?.rowData?.original;
            profiles.autorisation = copyData;
            dataProfiles.push(profiles);
        } else {
            dynamicTableWrapperContext?.rowData.forEach((profile: any) => {
                profile.autorisation = copyData;
                dataProfiles.push(profile);
            });
        }

        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers,
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            profiles: {
                                dataProfiles,
                            },
                        }),
                        method: "PUT",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error("Error while saving selection!");
                            }
                            handleSavedHistoryOnClickEvent(dataProfiles);
                            toast.success(
                                `${t("The profile is successfully updated")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            setValidateOnclick(true);
                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("There's an error")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
            setIsManagePermissionsModalOpened(false);
            setEditActionProfile(false);
            setlistSelectedData({});
            dynamicTableWrapperContext?.setCheckProfileAuthModelModification(
                false
            );
        }
    }

    async function handleSavedHistoryOnClickEvent(dataProfiles: any) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/history`;
        const responsable = localStorage.getItem("Name");
        const data: any = [];

        // Only Authorization Has change ! Check will be on Backend
        dataProfiles.forEach((profile: any) => {
            data.push({
                typeHistory: 4,
                oldData: {
                    designation: profile.Désignation,
                    Balise: profile.Balise,
                    Description: profile.Description,
                },
                newData: {
                    designation: profile.Désignation,
                    Balise: profile.Balise,
                    Description: profile.Description,
                },
                operator: responsable,
                uidUpdatedData: profile.uid,
            });
        });

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers,
                    body: JSON.stringify({
                        data,
                        customerAccountUUid: customerAccountId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error();
                        }
                    })
            );
        } catch (e: any) {
            toast.error(
                `${t("Error while saving profile authorization history")}!`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }

    const arrayOfCustomColumns: any[] = [
        {
            indexOFColumn: 3,
            columnName: t("Actions"),
            // eslint-disable-next-line react/no-unstable-nested-components
            customJsx: (e: any): JSX.Element => (
                <CustomJsx
                    isProfileListArchived={isProfileListArchived}
                    // eslint-disable-next-line react/jsx-no-bind
                    handleOnEditUser={handleOnEditUser}
                    e={e}
                />
            ),
        },
    ];

    async function listArchiveAccount(state: string) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/profiles?customerAccountUUid=${customerAccountId}&profileStatus=${state}&customerLang=${i18nextLng}`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers,
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((res) => {
                        setAccountNumber(res.data.length);
                    })
            );
        } catch (e) {
            // eslint-disable-next-line
            console.error();
        }
    }
    function handleHistoryOnClickEvent(selectedRow: any) {
        setIsHistoryModalOpened(!isHistoryModalOpened);
        dynamicTableWrapperContext?.setRowData({ ...selectedRow });
    }

    React.useEffect(() => {
        if (isProfileListArchived === true) {
            setProfileStatus("Archiver");
            listArchiveAccount("Activé");
        } else {
            setProfileStatus("Activé");
            listArchiveAccount("Archiver");
        }
    }, [isProfileListArchived]);

    return (
        <React.Fragment>
            <DynamicTable
                url={`${process.env.REACT_APP_ACCESS_API_URL}/profiles?customerAccountUUid=${customerAccountId}&profileStatus=${profileStatus}&customerLang=${i18nextLng}`}
                canSort
                name="table-user"
                canResize
                showGlobalFilter
                showFilter
                canSelect
                customSelect
                setSelectedRows={setSelectedRows}
                // eslint-disable-next-line react/no-unstable-nested-components
                actionColumn={(e: any) => {
                    return (
                        <ColumnActive
                            isProfileListArchived={isProfileListArchived}
                            isConfirmUnArchivedModal={isConfirmUnArchivedModal}
                            setIsConfirmUnArchivedModal={
                                setIsConfirmUnArchivedModal
                            }
                            setIsConfirmArchivedModal={
                                setIsConfirmArchivedModal
                            }
                            // eslint-disable-next-line react/jsx-no-bind
                            handleManagePermissionsOnClickEvent={
                                handleManagePermissionsOnClickEvent
                            }
                            isConfirmArchivedModal={isConfirmArchivedModal}
                            e={e}
                            // eslint-disable-next-line react/jsx-no-bind
                            handleHistoryOnClickEvent={
                                handleHistoryOnClickEvent
                            }
                        />
                    );
                }}
                arrayOfCustomColumns={arrayOfCustomColumns}
                setDataIsUpdated={dynamicTableWrapperContext?.setDataUpdated}
                dataIsUpdated={dynamicTableWrapperContext?.dataIsUpdated}
                setData={dynamicTableWrapperContext?.setDataTable}
                customJsxSideFilterButton={
                    <CustomActionFilter
                        selectedRows={selectedRows}
                        handleSubmit={handleSubmit}
                        accountNumber={accountNumber}
                        setIsConfirmUnArchivedModal={
                            setIsConfirmUnArchivedModal
                        }
                        setIsConfirmArchivedModal={setIsConfirmArchivedModal}
                        setIsManagePermissionsModalOpened={
                            setIsManagePermissionsModalOpened
                        }
                        setIsAuthorisationProfileAlert={
                            setIsAuthorisationProfileAlert
                        }
                        // eslint-disable-next-line react/jsx-no-bind
                        handleManagePermissionsOnClickEvent={
                            handleManagePermissionsOnClickEvent
                        }
                    />
                }
                minHeight={`calc(100vh - (370px + ${
                    isVisible ? "100px" : "0px"
                }))`}
                maxHeight={`calc(100vh - (370px + ${
                    isVisible ? "125px" : "0px"
                }))`}
            />
            <ConfirmArchivedModalProfile
                isOpen={isConfirmArchivedModal}
                setIsOpen={setIsConfirmArchivedModal}
                // eslint-disable-next-line react/jsx-no-bind
                listArchiveAccount={listArchiveAccount}
            />
            <ConfirmUnArchivedModalProfile
                isOpen={isConfirmUnArchivedModal}
                setIsOpen={setIsConfirmUnArchivedModal}
                // eslint-disable-next-line react/jsx-no-bind
                listArchiveAccount={listArchiveAccount}
            />
            <MangeProfilePermissions
                isOpen={isManagePermissionsModalOpened}
                setIsOpen={setIsManagePermissionsModalOpened}
                // eslint-disable-next-line react/jsx-no-bind
                handleSaveButtonOnClickEvent={
                    handleEditedProfileButtonOnClickEvent
                }
                setlistSelectedData={setlistSelectedData}
                listSelectedData={listSelectedData}
                editProfile={editProfile}
                setEditActionProfile={setEditActionProfile}
            />
            <AuthorisationProfileModel
                isOpen={isAuthorisationProfileAlert}
                setIsOpen={setIsAuthorisationProfileAlert}
                setIsManagePermissionsModalOpened={
                    setIsManagePermissionsModalOpened
                }
            />
            {isHistoryModalOpened ? (
                <HistoryModal
                    setIsModalOpened={setIsHistoryModalOpened}
                    isModalOpened={isHistoryModalOpened}
                    uid={dynamicTableWrapperContext?.rowData?.original.uid}
                    type={4}
                />
            ) : null}
        </React.Fragment>
    );
}
