/* eslint-disable max-len */
import React from "react";

export type ArrowDowntype = {
    props?: React.SVGProps<SVGSVGElement>;
    color?: string;
};
export function ArrowDownIcon({ props, color }: ArrowDowntype): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={20}
            height={20}
            {...props}
            fill={color === "black" ? "black" : "white"}
        >
            <path
                d="M12 15.5a1.993 1.993 0 0 1-1.414-.585L5.293 9.621l1.414-1.414L12 13.5l5.293-5.293 1.414 1.414-5.293 5.293A1.993 1.993 0 0 1 12 15.5Z"
                data-name="01 align center"
            />
        </svg>
    );
}
