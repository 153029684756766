/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable sonarjs/no-collapsible-if */
/* eslint-disable-next-line sonarjs/no-duplicate-string */
import React from "react";
import { AvForm } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import { StyledButton, CheckboxIcon } from "@aureskonnect/react-ui";
import { toast } from "react-toastify";

import { Card, CardFooter } from "reactstrap";

import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";

import "react-phone-number-input/style.css";

import { mutate } from "swr";
import { useSelector } from "react-redux";
import {
    DominantColor,
    DynamicTableWrapperContext,
    RequirementToast,
} from "@components/Common";
import { ManageAccessModal } from "@components/Common/Modals/AccessManagment/ManageAccessModal";
import { EMAIL_VALIDATION_REGEX, TYPEBoutique } from "@constants/index";
import { RootState } from "@store/index";
import ConfirmMailModal from "@components/Common/Modals/ConfirmMailModal";
import { toLowerCase } from "@helpers/general";

import { FormBody } from "./FormBody";

import "../style.css";

type typeAdduser = {
    // eslint-disable-next-line react/no-unused-prop-types
    userStatus: any;
};

export function AddUser({ userStatus }: typeAdduser): JSX.Element {
    const { t } = useTranslation();
    const tostEmailmsg = "The field ''Email'' must be filled correctly";
    const refs = React.useRef<HTMLDivElement[]>([]);
    const dominantColor: string = DominantColor();
    const customerAccountId = localStorage.getItem("customerAccountId");
    const TypeAccount = localStorage.getItem("TypeAccount");
    const [validateOnclick, setValidateOnclick] = React.useState<boolean>(true);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const responsable = localStorage.getItem("Name");
    const [isInvalidEmail, setIsInvalidEmail] = React.useState<boolean>(false);
    const [isInvalidTelephone] = React.useState<boolean>(false);

    const [invalidSubmit, setInvalidSubmit] = React.useState(false);

    const isEmailModifConfirmed = useSelector(
        (state: RootState) => state.accessSlice.isEmailModifConfirmed
    );

    const [user, setUser] = React.useState<Usertype>({
        Nom: "",
        Prénom: "",
        Email: "",
        "Téléphone 1": "",
        "Téléphone 2": "",
        Profil: "",
        Balise: "",
        Commentaire: "",
        access_authorization: [],
        isAdmin: false,
    });

    const [verifyEmailError, setVerifyEmailError] =
        React.useState<boolean>(false);

    const addToRefs = (el: HTMLDivElement) => {
        if (el && !refs?.current?.includes(el)) {
            refs?.current?.push(el);
        }
    };
    const [isManageAccessModalOpened, setIsManageAccessModalOpened] =
        React.useState<boolean>(false);

    const [isMailModalOpened, setIsMailModalOpened] =
        React.useState<boolean>(false);

    let verifyUserModification = false;

    async function handleSaveUserButtonOnClickEvent(userInfo: any) {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/add-user`;

        if (validateOnclick) {
            setValidateOnclick(false);
            try {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            // eslint-disable-next-line sonarjs/no-duplicate-string
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            customerAccountUUid: customerAccountId,
                            data: userInfo,
                        }),
                        method: "POST",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            setValidateOnclick(true);
                            if (data.error) {
                                throw Error();
                            }

                            toast.success(
                                `${t("The user is successfully added")!}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    icon: (
                                        <CheckboxIcon
                                            height={25}
                                            width={25}
                                            fill="white"
                                        />
                                    ),
                                }
                            );
                            if (userInfo.Profil.length === 0) {
                                toast.info(
                                    `${t("In order to send access data to")} ${
                                        userInfo.Nom
                                    } ${userInfo.Prénom} ${t(
                                        "by e-mail, you must fill in the profile field"
                                    )}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        delay: 3000,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            } else {
                                toast.success(
                                    // eslint-disable-next-line sonarjs/no-duplicate-string
                                    `${t("The access data was sent to")} ${
                                        userInfo.Nom
                                    } ${userInfo.Prénom} ${t("by e-mail")}`,
                                    {
                                        position: toast.POSITION.TOP_CENTER,
                                        delay: 3000,
                                        autoClose: 2000,
                                        theme: "colored",
                                        closeOnClick: true,
                                        pauseOnHover: true,
                                        draggable: true,
                                    }
                                );
                            }
                            dynamicTableWrapperContext?.setDataUpdated(true);
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                false
                            );
                        })
                );
            } catch (e: any) {
                toast.error(`${t("Error while saving user")}!`, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                });
            }
        }
    }
    // eslint-disable-next-line sonarjs/cognitive-complexity
    async function handleEditedUserButtonOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-user?userAccountStatus=${userStatus}`;
        setUser(toLowerCase(user));

        if (
            user.Email === "" ||
            verifyEmailError === true ||
            isInvalidEmail === true
        ) {
            RequirementToast(t(tostEmailmsg));       
        } else {
            if (user.Email !== dynamicTableWrapperContext?.rowData?.Email) {
                setIsMailModalOpened(!isMailModalOpened);
            }

            // eslint-disable-next-line no-lonely-if
            if (validateOnclick) {
                setValidateOnclick(false);
            
                if (user.Email === dynamicTableWrapperContext?.rowData?.Email) {
                    try {
                        mutate(
                            apiUrl,
                            await fetch(apiUrl, {
                                headers: {
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify({
                                    customerAccountUUid: customerAccountId,
                                    data: {
                                        ...user,
                                        ID: dynamicTableWrapperContext?.rowData
                                            ?.ID,
                                    },
                                }),
                                method: "PUT",
                            })
                                .then((response) => response.json())
                                .then((data) => {
                                    if (data.error) {
                                        throw Error(
                                            "Error while updating selection!"
                                        );
                                    }
                                    if (
                                        !invalidSubmit &&
                                        verifyUserModification
                                    ) {
                                        handleSavedEditHistoryOnClickEvent();
                                    }
                                    setValidateOnclick(true);
                                    toast.success(
                                        `${t(
                                            "The user is successfully updated"
                                        )!}`,
                                        {
                                            position: toast.POSITION.TOP_CENTER,
                                            autoClose: 2000,
                                            theme: "colored",
                                            closeOnClick: true,
                                            pauseOnHover: true,
                                            draggable: true,
                                            icon: (
                                                <CheckboxIcon
                                                    height={25}
                                                    width={25}
                                                    fill="white"
                                                />
                                            ),
                                        }
                                    );

                                    if (
                         user.selectedProfil.value !== undefined &&( (user.selectedProfil.value  !== dynamicTableWrapperContext?.rowData?.selectedProfil 
                           &&
                            isEmailModifConfirmed === true )||   (  user.selectedProfil.value !== "" && user.selectedProfil.value  !== dynamicTableWrapperContext?.rowData?.selectedProfil))
                        ) {
                            toast.success(
                                `${t("The access data was sent to")} ${
                                    user.Nom
                                } ${user.Prénom} ${t("by e-mail")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    delay: 3000,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                }
                            );
                        }


                                    dynamicTableWrapperContext?.setDataUpdated(
                                        true
                                    );
                                    dynamicTableWrapperContext?.setActionColumnDisplay(
                                        false
                                    );
                                })
                        );
                    } catch (e: any) {
                        toast.error(`${t("There's an error")}!`, {
                            position: toast.POSITION.TOP_CENTER,
                            autoClose: 2000,
                            theme: "colored",
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                        });
                    }
                }
                setInvalidSubmit(false);
            }
        }
    }

    async function handleSavedEditHistoryOnClickEvent() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/history`;

        const data = [
            {
                typeHistory: 7,
                oldData: {
                    Nom: `${dynamicTableWrapperContext?.rowData?.Nom}`,
                    Prénom: `${dynamicTableWrapperContext?.rowData?.Prénom}`,
                    Email: `${dynamicTableWrapperContext?.rowData?.Email}`,
                    "Téléphone 1": `${dynamicTableWrapperContext?.rowData?.["Téléphone 1"]}`,
                    "Téléphone 2": `${dynamicTableWrapperContext?.rowData?.["Téléphone 2"]}`,
                    Profil: `${dynamicTableWrapperContext?.rowData?.Profil}`,
                    Balise: `${dynamicTableWrapperContext?.rowData?.Balise}`,
                    Commentaire: `${dynamicTableWrapperContext?.rowData?.Commentaire}`,
                },
                newData: {
                    Nom: `${user.Nom}`,
                    Prénom: `${user.Prénom}`,
                    Email: `${user.Email}`,
                    "Téléphone 1": `${user["Téléphone 1"]}`,
                    "Téléphone 2": `${user["Téléphone 2"]}`,
                    Profil: `${user.Profil}`,
                    Balise: `${user.Balise}`,
                    Commentaire: `${user.Commentaire}`,
                },
                operator: responsable,
                uidUpdatedData: `${dynamicTableWrapperContext?.rowData?.ID}`,
            },
        ];

        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        data,
                        customerAccountUUid: customerAccountId,
                    }),
                    method: "POST",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error(
                                `${t("Error while saving a history of edited user account")}!`
                            );
                        }
                    })
            );
        } catch (e: any) {
            toast.error(`${t("Error while saving a history of edited user account")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    }

    const handleRefreshIconClick = (): void => {
        if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            setUser({
                Nom: dynamicTableWrapperContext?.rowData?.Nom,
                Prénom: dynamicTableWrapperContext?.rowData?.Prénom,
                Email: dynamicTableWrapperContext?.rowData?.Email,
                "Téléphone 1":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 1"],
                "Téléphone 2":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 2"],
                Profil: dynamicTableWrapperContext?.rowData?.Profil,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Commentaire: dynamicTableWrapperContext?.rowData?.Commentaire,
                access_authorization:
                    dynamicTableWrapperContext?.rowData?.access_authorization,
                selectedProfil:
                    dynamicTableWrapperContext?.rowData?.selectedProfil,
                isAdmin: dynamicTableWrapperContext?.rowData?.isAdmin,
            });
        } else {
            setUser({
                Nom: "",
                Prénom: "",
                Email: "",
                "Téléphone 1": "",
                "Téléphone 2": "",
                Profil: "",
                Balise: "",
                Commentaire: "",
                access_authorization: [],
                selectedProfil: {},
                isAdmin: false,
            });
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const handleOnscroll = (): void => {
        if (refs) {
            refs?.current?.forEach((elm: any) => {
                if (user.Nom === "") {
                    if (elm.id === "Nom") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''Last name'' field must be filled")
                        );
                    }
                } else if (user.Prénom === "") {
                    if (elm.id === "Prénom") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The ''First name'' field must be filled")
                        );
                    }
                } else if (user.Email === "") {
                    if (elm.id === "Email") {
                        elm.scrollIntoView({
                            behavior: "smooth",
                            block: "nearest",
                            inline: "start",
                        });
                        elm.focus();
                        RequirementToast(
                            t("The field ''Email'' must be filled")
                        );
                    }
                }
            });
        }
    };

    // eslint-disable-next-line sonarjs/cognitive-complexity
    const onValidSubmit = async () => {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/checkEmail?email=${user.Email}`;

        try {
            if (!EMAIL_VALIDATION_REGEX.test(user.Email)) {
                setIsInvalidEmail(true);
                RequirementToast(t(tostEmailmsg));
            } else if (user.Email === "" || isInvalidEmail === true) {
                RequirementToast(t(tostEmailmsg));
            } else if (verifyEmailError === true) {
                throw Error();
            }else if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
               if (
                    user.Nom !== dynamicTableWrapperContext?.rowData?.Nom ||
                    user.Prénom !==
                        dynamicTableWrapperContext?.rowData?.Prénom  ||
                    user.Profil !==
                        dynamicTableWrapperContext?.rowData?.Profil ||
                    user["Téléphone 1"] !==
                        dynamicTableWrapperContext?.rowData?.["Téléphone 1"] ||
                    user["Téléphone 2"] !==
                        dynamicTableWrapperContext?.rowData?.["Téléphone 2"] ||
                    user.Balise !==
                        dynamicTableWrapperContext?.rowData?.Balise ||
                    user.Commentaire !==
                        dynamicTableWrapperContext?.rowData?.Commentaire || user.Email !== dynamicTableWrapperContext?.rowData?.Email
                ) {
                    verifyUserModification = true;
                    handleEditedUserButtonOnClickEvent();
                } else {
                    dynamicTableWrapperContext?.setActionColumnDisplay(false);
                }
            } else {
                mutate(
                    apiUrl,
                    await fetch(apiUrl, {
                        headers: {
                            "Content-Type": "application/json",
                        },
                        method: "GET",
                    })
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.error) {
                                throw Error();
                            }
                        })
                );
                if (TypeAccount !== TYPEBoutique) {
                    setIsManageAccessModalOpened(!isManageAccessModalOpened);
                } else {
                    handleSaveUserButtonOnClickEvent(user);
                }
            }
        } catch (error: any) {
            toast.error(`${t("This email address is already in use")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
    };

    async function HandleUserEmailModification() {
        const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/edit-user?userAccountStatus=${userStatus}`;
        try {
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        customerAccountUUid: customerAccountId,
                        data: {
                            ...user,
                            ID: dynamicTableWrapperContext?.rowData?.ID,
                        },
                    }),
                    method: "PUT",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.error) {
                            throw Error("Error while saving selection!");
                        }
                        if (!invalidSubmit) {
                            handleSavedEditHistoryOnClickEvent();
                        } 
                        setValidateOnclick(true);
                        toast.success(
                            `${t("The user is successfully updated")!}`,
                            {
                                position: toast.POSITION.TOP_CENTER,
                                autoClose: 2000,
                                theme: "colored",
                                closeOnClick: true,
                                pauseOnHover: true,
                                draggable: true,
                                icon: (
                                    <CheckboxIcon
                                        height={25}
                                        width={25}
                                        fill="white"
                                    />
                                ),
                            }
                        );
                        if (
                         user.selectedProfil.value !== undefined &&( (user.selectedProfil.value  !== dynamicTableWrapperContext?.rowData?.selectedProfil 
                           &&
                            isEmailModifConfirmed === true )||   (  user.selectedProfil.value !== "" && user.selectedProfil.value  !== dynamicTableWrapperContext?.rowData?.selectedProfil))
                        ) {
                            toast.success(
                                `${t("The access data was sent to")} ${
                                    user.Nom
                                } ${user.Prénom} ${t("by e-mail")}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    delay: 3000,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                }
                            );
                        }else {
                            toast.info(
                                `${t("In order to send access data to")} ${
                                    user.Nom
                                } ${user.Prénom} ${t(
                                    "by e-mail, you must fill in the profile field"
                                )}`,
                                {
                                    position: toast.POSITION.TOP_CENTER,
                                    delay: 3000,
                                    autoClose: 2000,
                                    theme: "colored",
                                    closeOnClick: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                }
                            );
                        }
                        dynamicTableWrapperContext?.setDataUpdated(true);
                        dynamicTableWrapperContext?.setActionColumnDisplay(
                            false
                        );
                    })
            );
        } catch (e: any) {
            toast.error(`${t("There's an error")}!`, {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            });
        }
        setInvalidSubmit(false);
    }

    React.useEffect((): void => {
        if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            setUser({
                Nom: dynamicTableWrapperContext?.rowData?.Nom,
                Prénom: dynamicTableWrapperContext?.rowData?.Prénom,
                Email: dynamicTableWrapperContext?.rowData?.Email,
                "Téléphone 1":
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    dynamicTableWrapperContext?.rowData?.["Téléphone 1"],
                "Téléphone 2":
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    dynamicTableWrapperContext?.rowData?.["Téléphone 2"],
                Profil: dynamicTableWrapperContext?.rowData?.Profil,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Commentaire: dynamicTableWrapperContext?.rowData?.Commentaire,
                access_authorization:
                    dynamicTableWrapperContext?.rowData?.access_authorization,
                selectedProfil:
                    dynamicTableWrapperContext?.rowData?.selectedProfil,
                isAdmin: dynamicTableWrapperContext?.rowData?.isAdmin,
            });
        }
    }, [dynamicTableWrapperContext?.rowData]);



    React.useEffect(() => {
        if (
            user.Email !== dynamicTableWrapperContext?.rowData?.Email &&
            user.Email !== "" &&
            isEmailModifConfirmed === true
        ) {
            HandleUserEmailModification();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEmailModifConfirmed]);

    return (
        <React.Fragment>
            <Card style={{ border: "0px " }}>
                <StyleCardHeader
                    title={
                        dynamicTableWrapperContext?.rowData?.Nom !== ""
                            ? t("Edit user account")
                            : t("Add user account")
                    }
                    refreshIconFunction={handleRefreshIconClick}
                />

                <AvForm
                    onValidSubmit={() => {
                        onValidSubmit();
                    }}
                    onInvalidSubmit={() => {
                        handleOnscroll();
                        setInvalidSubmit(true);
                    }}
                    onKeyDown={(e: any) => {
                        if (e.key === "Enter") e.preventDefault();
                    }}
                >
                    <FormBody
                        user={user}
                        setUser={setUser}
                        addToRefs={addToRefs}
                        isInvalidTelephone={isInvalidTelephone}
                        isInvalidEmail={isInvalidEmail}
                        setIsInvalidEmail={setIsInvalidEmail}
                        verifyEmailError={verifyEmailError}
                        setVerifyEmailError={setVerifyEmailError}
                    />

                    <CardFooter className="Footercard p-0 ">
                        <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                            <div className="p-2 bd-highlight">
                                <StyledButton
                                    rounded
                                    variant={dominantColor}
                                    className="ctl_btn_validate"
                                >
                                    {t("Validate")}
                                </StyledButton>
                            </div>
                            <div className="p-2 bd-highlight ">
                                <StyledButton
                                    className="ctl_btn_cancel"
                                    rounded
                                    outline
                                    variant="light"
                                    onClick={() => {
                                        dynamicTableWrapperContext?.setActionColumnDisplay(
                                            false
                                        );
                                    }}
                                >
                                    {t("Cancel")}
                                </StyledButton>
                            </div>
                        </div>
                    </CardFooter>
                </AvForm>
            </Card>
            <ManageAccessModal
                isOpen={isManageAccessModalOpened}
                setIsOpen={setIsManageAccessModalOpened}
                // eslint-disable-next-line
                handleSaveUserButtonOnClickEvent={
                    handleSaveUserButtonOnClickEvent
                }
                user={user}
            />
            <ConfirmMailModal
                isOpen={isMailModalOpened}
                setIsOpen={setIsMailModalOpened}
                email={user.Email}
            />
        </React.Fragment>
    );
}
