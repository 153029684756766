import React from "react";

import { Navigate, useRoutes } from "react-router-dom";

import AuthLayout from "@components/AuthLayout";
import UserAccounts from "@pages/User/UserAccounts";
import BlockedAccounts from "@pages/User/BlockedAccounts";
import ArchivedAccounts from "@pages/User/ArchivedAccounts";
import Profile from "@pages/Configuration/profile";
import CustomerAccountListWrapper from "@pages/CustomerAccountListWrapper";

import Login from "@pages/Authentication/Login";
import UpdatePassword from "@pages/Authentication/UpdatePassword";

import { Callback } from "@pages/Authentication/Callback";

import AuthRouter from "./middleware/AuthRouter";
import AuthRouterAccess from "./middleware/AuthRouterAccess";

export default function Router() {
    return useRoutes([
        {
            element:
                process.env.REACT_APP_IS_SSO_ACTIVE === "false" ? (
                    <AuthRouterAccess>
                        <AuthLayout />
                    </AuthRouterAccess>
                ) : (
                    <AuthRouter>
                        <AuthLayout />
                    </AuthRouter>
                ),
            children: [
                { path: "users-accounts", element: <UserAccounts /> },
                { path: "blocked-accounts", element: <BlockedAccounts /> },
                { path: "archived-accounts", element: <ArchivedAccounts /> },
                { path: "profiles", element: <Profile /> },
                {
                    path: "customer-accounts",
                    element: <CustomerAccountListWrapper />,
                },
                {
                    path: "/customer-accounts",
                    element: <Navigate to="customer-accounts" />,
                },
                {
                    path: "/users-accounts",
                    element: <Navigate to="users-accounts" />,
                },
            ],
        },
        {
            element: <Login />,
            path: "/",
        },
        { path: "/update", element: <UpdatePassword /> },
        {
            element: <Callback />,
            path: "/Callback",
        },
    ]);
}
