import React from "react";
import { useTranslation } from "react-i18next";
import { ModalBody, ModalHeader, ModalFooter, Modal } from "reactstrap";

import {
    StyledButton,
    StyledIconButton,
    CrossIcon,
} from "@aureskonnect/react-ui";

type BloqArchiveAccountLoginModalType = {
    isModalOpened: boolean;
    setIsModalOpened: Function;
    msgTitleModal: string;
};

export function BloqArchiveAccountLogin({
    isModalOpened,
    setIsModalOpened,
    msgTitleModal,
}: BloqArchiveAccountLoginModalType) {
    const { t } = useTranslation();
    return (
        <Modal isOpen={isModalOpened} centered>
            <ModalHeader>
                <div
                    className="d-flex align-items-center justify-content-between"
                    style={{
                        height: "35px",
                        display: "grid",
                        gridTemplateColumns: "1.9fr 0.1fr",
                        gridTemplateRows: "1fr",
                        gap: "177px",
                    }}
                >
                    <p
                        style={{
                            color: "#2B2828",
                            opacity: "1",
                            fontWeight: "bold",
                            textAlign: "left",
                            font: "normal normal bold 16px/15px Segoe UI",
                        }}
                    >
                        {t("CONNEXION NON AUTORISÉE")}
                    </p>
                    <StyledIconButton
                        icon="CrossIcon"
                        onClick={() => {
                            setIsModalOpened(!isModalOpened);
                        }}
                    >
                        <CrossIcon cursor="pointer" height={10} width={10} />
                    </StyledIconButton>
                </div>
            </ModalHeader>
            <ModalBody>
                <p className="font-size-14">{t(msgTitleModal)}</p>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-end">
                <div className="d-flex align-items-center">
                    <div className="ml-3">
                        <StyledButton
                            disabled={false}
                            rounded
                            variant="light"
                            outline
                            size="sm"
                            style={{
                                background: "black",

                                color: "white",

                                padding: "0px",

                                width: "150px",

                                height: "45px",
                            }}
                            onClick={() => setIsModalOpened(!isModalOpened)}
                        >
                            {t("Close")}
                        </StyledButton>
                    </div>
                </div>
            </ModalFooter>
        </Modal>
    );
}
