import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SettingsIcon } from "@aureskonnect/react-ui";

import { RootState } from "@store/.";

import { DominantColor } from "@components/Common";
import { UserIcon } from "@components/Common/SvgIcons/UserIcon";

import { StyledSidebarMenu } from "./StyledSidebarMenu";

import "../index.css";

type SidebarProps = {
    brand: string;
};

export default function Sidebar({ brand }: SidebarProps) {
    const { t } = useTranslation();
    const dominantColor = DominantColor();
    const collapsed = useSelector(
        (state: RootState) => state.layoutSlice.collapsed
    );
    const selectedAccountId = localStorage.getItem("customerAccountId");


    return (
        <StyledSidebarMenu
            brand={
                <span
                    className="sid_btn_nameOfTheProject"
                    style={{
                        cursor: selectedAccountId !== "" ? "pointer" : "auto",
                    }}
                    role="button"
                    tabIndex={0}
                >
                    {collapsed ? brand.slice(0, 2) : brand}
                </span>
            }
            collapsed={collapsed}
            backgroundColor={dominantColor}
            menuItems={[
                {
                    icon: <UserIcon width={25} height={25} />,
                    link: "",
                    menuItem: t("User management"),
                    menuItemClass: "sid_btn_userManagement",
                    uid: "5090fe0f-69fc-9ff9-213b-d9a9defd990c",
                    subMenu: [
                        {
                            link: "users-accounts",
                            menuItem: t("User accounts"),
                            subMenuClass: "sid_btn_userAccount",
                            uid: "5090fe0f-69fc-9ff9-213b-d9a9defd970c",
                        },

                        {
                            link: "blocked-accounts",
                            menuItem: t("Blocked accounts"),
                            subMenuClass: "sid_btn_blockedAccount",
                            uid: "5090fe0f-69fc-9ff9-213b-d9a9defd971c",
                        },

                        {
                            link: "archived-accounts",
                            menuItem: t("Archived accounts"),
                            subMenuClass: "sid_btn_archivedAccount",
                            uid: "5090fe0f-69fc-9ff9-213b-d9a9defd972c",
                        },
                    ],
                },

                {
                    icon: <SettingsIcon height={25} width={25} />,
                    link: "",
                    menuItem: t("Configuration"),
                    menuItemClass: "sid_btn_setting",
                    uid: "5090fe0f-69fc-9ff9-214b-d9a9defd990c",
                    subMenu: [
                        {
                            link: "profiles",
                            menuItem: t("Profile"),
                            subMenuClass: "sid_btn_profile",
                            uid: "5090fe0f-69fc-9ff9-214b-d9a9defd960c",
                        },
                    ],
                },
            ]}
        />
    );
}
