export const StatusActive = "Activer";
export const statusArchived = "Archiver";
export const StatusBloquer = "Bloquer";
export const TYPEFRANCHISE = "Franchise";
export const TYPEBoutique = "Boutique";

export const ITEMS_PER_PAGE = 5;
export const EMAIL_VALIDATION_REGEX =
    /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9-]+.[a-z]{2,4}$/;
export const FORGOT_PASSWORD_CODE_LENGHT = 6;

export const listActionsModules = [
    "7350fe0f-69fc-9ff9-699d-d9a9defd77922",
    "8660fe0f-69fc-9ff9-699d-d9a9defd77987",
    "7550fe0f-69fc-9ff9-699d-d9a9defd77988",
    "8860fe0f-69fc-9ff9-688d-d9a9defd77983",
    "7550fe0f-69fc-9ff9-699d-d9a9def875872",
    "8860fe0f-69fc-9ff9-699d-d9a9defd77983",
    "8550fe0f-69fc-9ff9-699d-d9a9defd77988",
];

// Profile Authorization ID for Access project
// ID used to check permission for actions: Visible, Consult, Modify
export const PROFILE_ACCESS_ATHORIZATION_ID =
    "5090fe0f-69fc-9ff9-214b-d9a9defd960c";
export const USER_ACCOUNT_ACCESS_ATHORIZATION_ID =
    "5090fe0f-69fc-9ff9-213b-d9a9defd970c";
export const USER_BLOCKED_ACCESS_ATHORIZATION_ID =
    "5090fe0f-69fc-9ff9-213b-d9a9defd971c";
export const USER_ARCHIVED_ACCESS_ATHORIZATION_ID =
    "5090fe0f-69fc-9ff9-213b-d9a9defd972c";

export const SETTING_LOCAL_KIOSK =
    "a60a2f53-9d31-4bf7-a0e5-9fda2fce797a";
export const APPEARANCE_ID = "a60a2f53-9512-2567-a0e5-9fda2fce797a";
export const ADD_TAG_CATALOGUE =
    "3290zh8f-69fc-9ff9-699d-d9a9defd970dp";
export const ARCHIVE_TAG_CATALOGUE =
    "3290rd6f-69fc-9ff9-699d-d9a9defd9508o";
export const DUPLICATE_TAG_CATALOGUE =
    "1290rd6f-69fc-9ff9-688d-d9a9defd9506m";
export const EDIT_TAG_CATALOGUE =
    "5690rd6f-59fc-9ff9-688d-d9a9defd9503s";
export const ADD_STEP_CATALOGUE =
    "6690re3f-69en-9ff9-699d-d9a9defd980d";
export const ARCHIVE_STEP_CATALOGUE =
    "8290ds8f-66sc-5cz9-699d-d3t0refd960d";
export const DUPLICATE_STEP_CATALOGUE =
    "8290ds8f-66sc-5cc9-699d-d3t0refd970d";
export const EDIT_STEP_CATALOGUE =
    "4490re3f-69en-9uu9-699d-d9a9defd985w";
export const IMPOERT_EXEL_FILE_STEPS_CATALOGUE =
    "5590nh8f-66rn-9ff9-699d-d9a0refd441d";
export const ADD_OPTION_CATALOGUE =
    "4k59rd6f-59fc-9aa9-688d-d9a9defd95er0";
export const ARCHIVE_OPTION_CATALOGUE =
    "6q58fd6f-59fc-9aa9-688d-d9a9defd95er0";
export const DUPLICATE_OPTION_CATALOGUE =
    "6w58fd6f-59fc-9aa9-688d-er59defd95er1";
export const EDIT_OPTION_CATALOGUE =
    "8f58fd6f-59fc-9aa9-655d-er59defd95er2";
export const ARCHIVE_ARTICLE_CATALOGUE =
    "5590zk8f-60sc-5gw9-699d-d9a0refd990d";
export const ADD_ARTICLE_CATALOGUE =
    "5590zk8f-69fc-9ff9-699d-d9a9defd990d";
export const DUPLICATE_ARTICLE_CATALOGUE =
    "5590zk8f-66sc-5gg9-699d-d9a0refd990d";
export const EDIT_ARTICLE_CATALOGUE =
    "2290zk8f-69fc-9ff9-699d-d9a9defd996m";
export const ADD_REDERECTION_BUTTON_ARTICLE_CATALOGUE =
    "5590re3f-69fc-9ff9-699d-d9a9defd990d";
export const IMPOERT_EXEL_FILE_ARTICLE_CATALOGUE =
    "5590zk8f-66sc-9ff9-699d-d9a0refd990d";
export const CREATE_WAY_CATALOGUE =
    "5460jh0f-82fc-9ff9-688d-d9a9wxfd77921";
export const ADD_ALLERGENS_CATALOGUE =
    "1259rd6f-59fc-9aa9-688d-d9a9defd9515x";
export const ARCHIVE_ALLERGENS_CATALOGUE =
    "2530rd6f-59fc-9aa9-688d-d9a9defd9508o";
export const EDIT_ALLERGENS_CATALOGUE =
    "4780rd6f-59fc-9aa9-688d-d9a9defd9504x";
export const DUPLICATE_ALLERGENS_CATALOGUE =
    "6s30rd6f-59fc-9aa9-688d-d9a9defd95fg1";
export const ARCHIVE_CATEGORIES_CATALOGUE_UUID = "5590zk8f-66sc-5cc9-688u-d9a0refd970d";
export const DUPLICATE_CATEGORIES_CATALOGUE_UUID = "5590zk8f-66sc-5cc9-699d-d9a0refd970d";
export const ADD_CATEGORIES_CATALOGUE_UUID = "1190re3f-69fc-9ff9-699d-d9a9defd990d";
export const UPDATE_CATEGORIES_CATALOGUE_UUID = "8590re3f-69fc-9ff9-699d-d9a9defd995u";
export const IMPOERT_EXEL_FILE_CATEGORIES_CATALOGUE_UUID = "5590nh8f-66sc-9ff9-699d-d9a0refd991d";

export const AUTOMATIC_TRANSLATION = "cc030cd4-ef4d-486e-a689-39b11178625c"


export const APPLICATION_UID = [
    AUTOMATIC_TRANSLATION,
    SETTING_LOCAL_KIOSK,
    APPEARANCE_ID,
    ADD_TAG_CATALOGUE,
    ARCHIVE_TAG_CATALOGUE,
    DUPLICATE_TAG_CATALOGUE,
    EDIT_TAG_CATALOGUE,
    ADD_STEP_CATALOGUE,
    ARCHIVE_STEP_CATALOGUE,
    EDIT_STEP_CATALOGUE,
    DUPLICATE_STEP_CATALOGUE,
    IMPOERT_EXEL_FILE_STEPS_CATALOGUE,
    ADD_OPTION_CATALOGUE,
    ARCHIVE_OPTION_CATALOGUE,
    DUPLICATE_OPTION_CATALOGUE,
    EDIT_OPTION_CATALOGUE,
    ARCHIVE_ARTICLE_CATALOGUE
    , ADD_ARTICLE_CATALOGUE
    , DUPLICATE_ARTICLE_CATALOGUE
    , EDIT_ARTICLE_CATALOGUE
    , ADD_REDERECTION_BUTTON_ARTICLE_CATALOGUE,
    IMPOERT_EXEL_FILE_ARTICLE_CATALOGUE,
    CREATE_WAY_CATALOGUE,
    ADD_ALLERGENS_CATALOGUE,
    ARCHIVE_ALLERGENS_CATALOGUE,
    EDIT_ALLERGENS_CATALOGUE,
    DUPLICATE_ALLERGENS_CATALOGUE,
    ARCHIVE_CATEGORIES_CATALOGUE_UUID,
    DUPLICATE_CATEGORIES_CATALOGUE_UUID,
    ADD_CATEGORIES_CATALOGUE_UUID,
    UPDATE_CATEGORIES_CATALOGUE_UUID,
    IMPOERT_EXEL_FILE_CATEGORIES_CATALOGUE_UUID
];


