import { createSlice } from "@reduxjs/toolkit";

type EditObserverSliceType = {
    isEditing: boolean;
    isDirty: boolean;
    oldValue: any;
    currentValue: any;
};

const initialState: EditObserverSliceType = {
    isEditing: false,
    isDirty: false,
    oldValue: null,
    currentValue: null,
};

export const editObserverSlice = createSlice({
    name: "editObserver",
    initialState,
    reducers: {
        setIsEditing: (state, action) => {
            state.isEditing = action.payload;
        },
        setIsDirty: (state, action) => {
            state.isDirty = action.payload;
        },
        setOldValue: (state, action) => {
            state.oldValue = action.payload;
        },
        setCurrentValue: (state, action) => {
            state.currentValue = action.payload;
        },
        initEditObserver: (state) => {
            state.currentValue = initialState.currentValue;
            state.isDirty = initialState.isDirty;
            state.isEditing = initialState.isEditing;
            state.oldValue = initialState.oldValue;
        },
    },
});

export const {
    setIsEditing,
    setOldValue,
    setCurrentValue,
    setIsDirty,
    initEditObserver,
} = editObserverSlice.actions;

export default editObserverSlice.reducer;
