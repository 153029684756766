import { createSlice } from "@reduxjs/toolkit";

type storeType = {
    isOpen: boolean;
    user: {
        Nom: string,
        Prenom: string,
        Email: string,
        "Telephone 1": number,
        "Telephone 2": number,
        Profil: string,
        Balise: string,
        Commentaire: string,
    }
}
const initialState: storeType = {
    isOpen: false,
    user: {
        Nom: "",
        Prenom: "",
        Email: "",
        "Telephone 1": 0,
        "Telephone 2": 0,
        Profil: "",
        Balise: "",
        Commentaire: "",
    },

}

export const userAccountSlice = createSlice({
    name: "userAccount",
    initialState,
    reducers: {
        setIsOpen: (state, action) => {
            state.isOpen = action.payload
        },
        setUser: (state, action) => {
            state.user = action.payload
        },

    }
});

export const { setIsOpen, setUser, } = userAccountSlice.actions;

export default userAccountSlice.reducer;