import React from "react";

type PropsType = {
    width?: number;
    height?: number;
};

export function ConsultIcon({ width, height }: PropsType): JSX.Element {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 46 46"
        >
            <g
                id="Groupe_31810"
                data-name="Groupe 31810"
                transform="translate(5497 8137)"
            >
                <rect
                    id="Rectangle"
                    width="46"
                    height="46"
                    rx="6"
                    transform="translate(-5497 -8137)"
                    fill="#fff"
                    opacity="0"
                />
                <path
                    id="search-alt"
                    d="M8.571,8.762V6.571h8.762v2.19ZM5.286,24.1A1.1,1.1,0,0,1,4.19,23V3.286a1.1,1.1,0,0,1,1.1-1.1H21.714V12.6a7.133,7.133,0,0,1,2.19,1.446V0H5.286A3.286,3.286,0,0,0,2,3.286V23a3.286,3.286,0,0,0,3.286,3.286h13.69a7.091,7.091,0,0,1-5.124-2.19ZM24.5,26.239l-2.91-2.91a4.967,4.967,0,1,1,1.549-1.549l2.91,2.91ZM18.976,21.9a2.738,2.738,0,1,0-2.738-2.738A2.738,2.738,0,0,0,18.976,21.9Z"
                    transform="translate(-5488 -8127)"
                />
            </g>
        </svg>
    );
}
