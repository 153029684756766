/* This Component Contain actions multiple for User Account
    - Manage Account Access (Only admin)
    - Check History
    - Block Account
    - Archive Account
*/
import React, { useState, useEffect } from "react";
import {
    SettingsIcon,
    TrashIcon,
    VerticalDotsIcon,
    DocumentIcon,
} from "@aureskonnect/react-ui";
import { toast } from "react-toastify";
import { BlockIcon } from "@components/Common/SvgIcons/BlockIcon";
import {
    TYPEBoutique,
    USER_ACCOUNT_ACCESS_ATHORIZATION_ID,
} from "@constants/index";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

type ActionMultiUserAccountType = {
    selectedRows?: any[];
    handleManageAccessOnClickEvent?: Function;
    handleBlockAccountOnClickEvent?: Function;
    setIsConfirmArchivedModal?: React.Dispatch<React.SetStateAction<boolean>>;
    isConfirmArchivedModal?: boolean;
    handleHistorysOnClickEvent: Function;
};

export function ActionMultiUserAccount({
    selectedRows,
    handleBlockAccountOnClickEvent,
    handleManageAccessOnClickEvent,
    setIsConfirmArchivedModal,
    isConfirmArchivedModal,
    handleHistorysOnClickEvent,
}: ActionMultiUserAccountType) {
    const { t } = useTranslation();
    const TypeAccount = localStorage.getItem("TypeAccount");
    const [adminSelected, setAdminSelected] = useState<boolean>(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );


    const errorTost = () => {
        return toast.error(
            `${t("This action is not allowed on the administrator account")}!`,
            {
                position: toast.POSITION.TOP_CENTER,
                autoClose: 2000,
                theme: "colored",
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
            }
        );
    };

    // Handle multi account ACCESS action on click event
    function handleMultiAccessOnclick() {
        if (!adminSelected) {
            handleManageAccessOnClickEvent?.(selectedRows);
        } else {
            errorTost();
        }
    }

    // Handle multi account BLOCK action on click event
    function handleMultiBlockAction() {
        if (!adminSelected) {
            handleBlockAccountOnClickEvent?.(selectedRows);
        } else {
            errorTost();
        }
    }

    // Handle multi account ARCHIVER action on click event
    function handleMultiArchiverAction() {
        if (!adminSelected) {
            dynamicTableWrapperContext?.setRowData(selectedRows);
            setIsConfirmArchivedModal?.(!isConfirmArchivedModal);
        } else {
            errorTost();
        }
    }

    useEffect(() => {
        // Check if Admin account on selected rows
        const checkIsAdmin = () => {
            const adminList = selectedRows?.filter(
                (item: any) => item.isAdmin === true
            );
            if (adminList?.length !== 0) {
                setAdminSelected(true);
            } else {
                setAdminSelected(false);
            }
        };
        checkIsAdmin();
    }, [selectedRows]);

    return (
        // OPEN multi actions on users account
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            {/* Manage Account Access ONLY Admin action */}
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {TypeAccount !== TYPEBoutique ? (
                    <div
                        role="button"
                        tabIndex={-3}
                        className={`dropdown-item pl-1 cmn_btn_manageAccess ${
                            selectedRows &&
                            selectedRows?.length > 0 &&
                            authorization[
                                USER_ACCOUNT_ACCESS_ATHORIZATION_ID
                            ] !== undefined &&
                            authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                                .Modifie
                                ? ""
                                : "disabled_clz"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleMultiAccessOnclick();
                        }}
                    >
                        <SettingsIcon width={35} height={25} />
                        <span className="ml-2">
                            {t("Manage access to accounts")}
                        </span>
                    </div>
                ) : null}{" "}
                {/* Display Account History */}
                <div
                    role="button"
                    tabIndex={-2}
                    className={`dropdown-item pl-1 cmn_btn_multipleHistorical ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleHistorysOnClickEvent(selectedRows);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                {/* Block Account Action */}
                <div
                    role="button"
                    tabIndex={-1}
                    className={`dropdown-item pl-1 cmn_btn_blockAccounts ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiBlockAction();
                    }}
                >
                    <BlockIcon />
                    <span className="ml-2">{t("Block account(s)")}</span>
                </div>
                {/* Archive Account Action */}
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item pl-1 cmn_btn_multiArchived ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleMultiArchiverAction();
                    }}
                >
                    <TrashIcon
                        width={35}
                        height={26}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span className="ml-2">{t("Archive account(s)")}</span>
                </div>
            </div>
        </div>
    );
}
