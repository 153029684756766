import React from "react";
/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    EyeCrossedIcon,
    EyeIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import classnames from "classnames";

import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import { updateUserChildsAccess } from "@store/features/accessSlice";

type ActionCustonFiltertype = {
    selectedRows: any[];
    // eslint-disable-next-line
    setSelectedRows: Function;
};
function AccessActionColumn({ selectedRows }: ActionCustonFiltertype) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // eslint-disable-next-line no-unused-vars
    function handleMultipleAtivationOnClickEvent(authorization: boolean) {
        selectedRows.forEach((rows: any) => {
            dispatch(
                updateUserChildsAccess({
                    userAccessStatus: authorization,
                    entityChildId: rows.id,
                })
            );
        });
    }

    return (
        <div className="d-flex justify-content-end align-items-end">
            <div className="dropdown">
                <VerticalDotsIcon
                    className="cmn_drp_menuAction"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    style={{ cursor: "pointer" }}
                    height={25}
                    width={25}
                    fill="black"
                />
                <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton"
                >
                    <div
                        className={`dropdown-item pl-1 ctl_btn_manageAccess  ${
                            selectedRows.length === 0 ? "disabled_clz" : ""
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleMultipleAtivationOnClickEvent(true);
                        }}
                    >
                        <EyeIcon width={35} height={25} />
                        <span className="ml-2">{t("Multiple activation")}</span>
                    </div>

                    <div
                        style={{ cursor: "pointer" }}
                        className={`${classnames(
                            "dropdown-item pl-1 pointer__clz cmn_btn_historical"
                        )}  ${selectedRows.length === 0 ? "disabled_clz" : ""}`}
                        onClick={() => {
                            handleMultipleAtivationOnClickEvent(false);
                        }}
                    >
                        <EyeCrossedIcon width={35} height={25} />
                        <span className="ml-2">
                            {t("Multiple desactivation")}
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AccessActionColumn;
