/* eslint-disable react/no-array-index-key */
/* eslint-disable no-confusing-arrow */
import React from "react";

import { StyledH1 } from "@aureskonnect/react-ui";
import { useSelector } from "react-redux";

import { RootState } from "@store/index";
import { Wrapper } from "../../Common/StyledComponent/StyledWrapperSideBarMenu";
import { Menu } from "./Menu";

import "bootstrap/dist/css/bootstrap.min.css";

export function StyledSidebarMenu({
    brand,
    menuItems,
    backgroundColor,
    collapsed,
}: StyledSidebarMenuPropsType) {
    const sidebarItems = React.useMemo(
        (): MenuItemsType[] => menuItems,
        [menuItems]
    );
    const dominantColor: any = useSelector(
        (State: RootState): string =>
            State.colorSlice.dominantColor
    );
    document.documentElement.style.setProperty(
        "--dominantColor",
        dominantColor
    );

    return (
        <Wrapper backgroundColor={backgroundColor} collapsed={collapsed}>
            {brand ? (
                <StyledH1 className="pt-2 pb-3 text-white d-flex justify-content-center brand__clz">
                    {typeof brand === "string" && collapsed === true
                        ? brand.slice(0, 2)
                        : brand}
                </StyledH1>
            ) : null}

            <div
                className="sidebar-menu__clz py-3"
                style={{ minHeight: "100%" }}
            >
                {sidebarItems.map(
                    (
                        {
                            link,
                            menuItem,
                            subMenu,
                            icon,
                            menuItemClass,
                            uid,
                        }: MenuItemsType,
                        index: number
                    ) => (
                        <Menu
                            key={index}
                            link={link}
                            menuItem={menuItem}
                            icon={icon}
                            subMenu={subMenu}
                            index={index}
                            collapsed={collapsed}
                            backgroundColor={backgroundColor}
                            menuItemClass={menuItemClass}
                            uid={uid}
                        />
                    )
                )}
            </div>
        </Wrapper>
    );
}
