import React from "react";

export default function PortalLogoSvgIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={502.999}
            height={92}
            {...props}
            viewBox="0 0 502.999 92"
        >
            <g
                id="Groupe_31929"
                data-name="Groupe 31929"
                transform="translate(-732.999 -124)"
            >
                <g
                    id="Groupe_9809"
                    data-name="Groupe 9809"
                    transform="translate(732.999 124)"
                >
                    <g
                        id="Groupe_9781"
                        data-name="Groupe 9781"
                        transform="translate(0 19.774)"
                    >
                        <path
                            id="Tracé_9359"
                            data-name="Tracé 9359"
                            d="M-658.263,736.963V687.53h36.655v8.361h-26.673v10.961h24.819v8.328h-24.819v13.456h27.618v8.328Z"
                            transform="translate(658.263 -687.53)"
                            fill="#333"
                        />
                        <path
                            id="Tracé_9360"
                            data-name="Tracé 9360"
                            d="M-620.685,736.963V695.891h-14.667V687.53h39.283v8.361H-610.7v41.072Z"
                            transform="translate(677.873 -687.53)"
                            fill="#333"
                        />
                        <path
                            id="Tracé_9361"
                            data-name="Tracé 9361"
                            d="M-610.642,736.963V687.53h9.981v21.952L-580.5,687.53h13.42l-18.613,19.255,19.625,30.177H-578.98l-13.589-23.2-8.092,8.263v14.936Z"
                            transform="translate(699.023 -687.53)"
                            fill="#333"
                        />
                    </g>
                    <rect
                        id="Rectangle_4460"
                        data-name="Rectangle 4460"
                        width="1.856"
                        height="50.11"
                        transform="translate(143.395 18.79)"
                        fill="#333"
                    />
                    <text
                        id="Connexion"
                        transform="translate(154.999 69)"
                        fill="#2a3042"
                        fontSize="66"
                        fontFamily="Poppins-Light, Poppins"
                        fontWeight="300"
                    >
                        <tspan x="0" y="0">
                            Connexion
                        </tspan>
                    </text>
                </g>
            </g>
        </svg>
    );
}
