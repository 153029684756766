import React from "react";
import { AvForm, AvGroup } from "availity-reactstrap-validation";
import { useTranslation } from "react-i18next";
import {
    StyledTextInput,
    StyledLabel,
    StyledButton,
} from "@aureskonnect/react-ui";
import PhoneInput from "react-phone-input-2";
import { Card, CardFooter, Input } from "reactstrap";

import StyleCardHeader from "@components/Common/StyledComponent/StyledCardHeader";
import { CustomCardBody } from "@components/Common/CustomCardStyled";
import { DynamicTableWrapperContext } from "@components/Common";

import "react-phone-input-2/lib/style.css";
import "../UserAccounts/style.css";

export function ConsultUser(): JSX.Element {
    const { t } = useTranslation();

    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const selectedCountryCode = localStorage.getItem("selectedCountryCode");

    const [user, setUser] = React.useState<Usertype>({
        Nom: "",
        Prénom: "",
        Email: "",
        "Téléphone 1": 0,
        "Téléphone 2": 0,
        Profil: "",
        Balise: "",
        Commentaire: "",
        isAdmin: false,
    });

    React.useEffect((): void => {
        if (dynamicTableWrapperContext?.rowData?.Nom !== "") {
            setUser({
                Nom: dynamicTableWrapperContext?.rowData?.Nom,
                Prénom: dynamicTableWrapperContext?.rowData?.Prénom,
                Email: dynamicTableWrapperContext?.rowData?.Email,
                "Téléphone 1":
                    dynamicTableWrapperContext?.rowData?.["Téléphone 1"],
                "Téléphone 2":
                    // eslint-disable-next-line sonarjs/no-duplicate-string
                    dynamicTableWrapperContext?.rowData?.["Téléphone 2"],
                Profil: dynamicTableWrapperContext?.rowData?.Profil,
                Balise: dynamicTableWrapperContext?.rowData?.Balise,
                Commentaire: dynamicTableWrapperContext?.rowData?.Commentaire,
                selectedProfil:
                    dynamicTableWrapperContext?.rowData?.selectedProfil,
                isAdmin: dynamicTableWrapperContext?.rowData?.isAdmin,
            });
        }
    }, [dynamicTableWrapperContext?.rowData]);

    return (
        <Card style={{ border: "0px " }}>
            <StyleCardHeader title={t("View archived account")} />
            <AvForm>
                <CustomCardBody
                    istablet="true"
                    isvisible="true"
                    className="card-body__clz"
                >
                    <AvGroup>
                        <StyledLabel htmlFor="Nom" className=" mt-3">
                            {t("Last name")}
                        </StyledLabel>
                        <StyledTextInput
                            autoComplete="off"
                            id="nom"
                            name="nom"
                            placeholder={t("Enter")}
                            type="text"
                            value={user.Nom}
                            disabled
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="Prenom" className=" mt-3">
                            {t("First name")}
                        </StyledLabel>
                        <StyledTextInput
                            autoComplete="off"
                            id="Prenom"
                            name="Prenom"
                            placeholder={t("Enter")}
                            type="text"
                            value={user.Prénom}
                            disabled
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="Email" className=" mt-3">
                            {t("Email")}
                        </StyledLabel>
                        <StyledTextInput
                            autoComplete="off"
                            id="Email"
                            name="Email"
                            placeholder={t("Enter")}
                            type="text"
                            value={user.Email}
                            disabled
                        />
                    </AvGroup>
                    <div
                        className="d-flex flex-row align-items-center justify-content-between"
                        style={{ gap: "42px", marginLeft: "5px" }}
                    >
                        <AvGroup>
                            <StyledLabel htmlFor="Telephone" className=" mt-3">
                                {`${t("Telephone")} 1`}
                            </StyledLabel>
                            <PhoneInput
                                country={
                                    dynamicTableWrapperContext?.rowData?.Nom ===
                                    ""
                                        ? (selectedCountryCode as string)
                                        : "fr"
                                }
                                value={user?.["Téléphone 1"]}
                                onChange={(phone) =>
                                    setUser({
                                        ...user,
                                        "Téléphone 1": phone,
                                    })
                                }
                                disabled
                            />
                        </AvGroup>
                        <AvGroup>
                            <StyledLabel htmlFor="Telephone" className=" mt-3">
                                {`${t("Telephone")} 2`}
                            </StyledLabel>
                            <PhoneInput
                                country={selectedCountryCode as string}
                                value={
                                    user?.["Téléphone 2"].length > 0
                                        ? user?.["Téléphone 2"]
                                        : ""
                                }
                                onChange={(phone) =>
                                    setUser({
                                        ...user,
                                        "Téléphone 2": phone,
                                    })
                                }
                                disabled
                            />
                        </AvGroup>
                    </div>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className=" mt-3">
                            {t("Profile")}
                        </StyledLabel>
                        <Input
                            value={user.Profil as string}
                            name="profil"
                            id="profil"
                            disabled
                        />
                    </AvGroup>
                    {/* Display set it to none because we will need tag on the future it just disable for now */}
                    <AvGroup style={{ display: "none" }}>
                        <StyledLabel htmlFor="text" className=" mt-3">
                            {t("Tag")}
                        </StyledLabel>
                        <Input
                            name="mark"
                            nooptionsmessage={t("No options")}
                            disabled
                        />
                    </AvGroup>
                    <AvGroup>
                        <StyledLabel htmlFor="text" className=" mt-3">
                            {t("Commentary")}
                        </StyledLabel>
                        <StyledTextInput
                            maxlength={2000}
                            autoComplete="off"
                            id="nom"
                            name="nom"
                            type="textarea"
                            value={user.Commentaire}
                            disabled
                        />
                    </AvGroup>
                </CustomCardBody>

                <CardFooter className="Footercard p-0 ">
                    <div className=" d-flex flex-wrap d-flex flex-row-reverse bd-highlight ">
                        <div className="p-2 bd-highlight car_btn_close">
                            <StyledButton
                                rounded
                                outline
                                variant="light"
                                onClick={() => {
                                    dynamicTableWrapperContext?.setActionColumnDisplay(
                                        false
                                    );
                                }}
                            >
                                {t("Close")}
                            </StyledButton>
                        </div>
                    </div>
                </CardFooter>
            </AvForm>
        </Card>
    );
}
