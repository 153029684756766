import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";
import { APPLICATION_UID } from "@constants/index";
import React from "react";
import Switch from "react-switch";

type VisibilityColmun = {
    Data: any;
    selectedRow: any;
    setlistSelectedData: Function;
    editProfile: boolean;
};
function VisibilityColumn({
    Data,
    selectedRow,
    setlistSelectedData,
    editProfile,
}: VisibilityColmun) {
    const [ischeckedVisibility, setIsCheckedVisibility] = React.useState(false);
    const dataProject: any = JSON.parse(
        localStorage.getItem("dataProject") as any
    );
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    // Check For Simple or Mulitple Profile Selection Action
    let SelectedProfil = dynamicTableWrapperContext?.rowData?.original
        ? dynamicTableWrapperContext?.rowData?.original
        : dynamicTableWrapperContext?.rowData[0];

    // Check if multiple authorization action
    if (dynamicTableWrapperContext?.multiAuthorizationProfileData) {
        // check if Update profile action
        if (editProfile) {
            SelectedProfil.autorisation = {
                ...SelectedProfil.autorisation,
                ...dynamicTableWrapperContext?.multiAuthorizationProfileData,
            };

            // Add Profile Action
        } else {
            SelectedProfil = dynamicTableWrapperContext?.addedProfile;
            SelectedProfil.autorisation = {
                ...dataProject,
                ...dynamicTableWrapperContext?.multiAuthorizationProfileData,
            };
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function EditProfile() {
        if (SelectedProfil.autorisation[selectedRow.id].Visible === true) {
            if (ischeckedVisibility === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: false,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: true,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            }
        } else if (ischeckedVisibility === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: true,
                Consult:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Consult
                        : SelectedProfil.autorisation[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Modifie
                        : SelectedProfil.autorisation[selectedRow.id].Modifie,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: false,
                Consult:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Consult
                        : SelectedProfil.autorisation[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] !== undefined
                        ? Data[selectedRow.id].Modifie
                        : SelectedProfil.autorisation[selectedRow.id].Modifie,
            };
        }
    }

    // eslint-disable-next-line sonarjs/cognitive-complexity
    function AddProfile() {
        if (SelectedProfil.autorisation[selectedRow.id].Visible === true) {
            if (ischeckedVisibility === false) {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: false,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            } else {
                Data[selectedRow.id] = {
                    Id: selectedRow.id,
                    IdParent: selectedRow.idParent,
                    Visible: true,
                    Consult:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Consult
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Consult,
                    Modifie:
                        Data[selectedRow.id] !== undefined
                            ? Data[selectedRow.id].Modifie
                            : SelectedProfil.autorisation[selectedRow.id]
                                  .Modifie,
                };
            }
        } else if (ischeckedVisibility === false) {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: true,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        } else {
            Data[selectedRow.id] = {
                Id: selectedRow.id,
                IdParent: selectedRow.idParent,
                Visible: false,
                Consult:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Consult,
                Modifie:
                    Data[selectedRow.id] === undefined
                        ? false
                        : Data[selectedRow.id].Modifie,
            };
        }
    }

    // ! Don't DELETE The useEffect
    // ! Used for multiple authorization to update table after multiple authorization switch changes.
    React.useEffect(() => {}, [
        SelectedProfil,
        dynamicTableWrapperContext?.multiAuthorizationProfileData,
    ]);

    return (
        <div className="d-flex align-items-center pfl_icn_enableDisableVisibility">
            {APPLICATION_UID.includes(selectedRow.id) ? null : (
                <Switch
                    onChange={() => {
                        setIsCheckedVisibility(!ischeckedVisibility);

                        if (editProfile) {
                            EditProfile();
                        } else {
                            AddProfile();
                        }

                        setlistSelectedData(Data);
                    }}
                    checked={
                        SelectedProfil &&
                        SelectedProfil.autorisation[selectedRow.id] !==
                            undefined &&
                        (SelectedProfil as any).autorisation[selectedRow.id]
                            .Visible === true
                            ? !ischeckedVisibility
                            : ischeckedVisibility
                    }
                    offColor="#E30613"
                    onColor="#34C38F"
                />
            )}
        </div>
    );
}

export default VisibilityColumn;
