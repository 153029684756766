import { PencilIcon } from "@aureskonnect/react-ui";
import { DynamicTableWrapperContext } from "@components/Common";
import LockIconWithToolTip from "@components/Common/LockIconWithToolTip/LockIconWithToolTip";
import { ConsultIcon } from "@components/Common/SvgIcons/ConsultIcon";
import { RootState } from "@store/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { PROFILE_ACCESS_ATHORIZATION_ID } from "@constants/index";

type customActionType = {
    isProfileListArchived: boolean;
    handleOnEditUser: Function;
    e: any;
};

function CustomJsx({
    isProfileListArchived,
    handleOnEditUser,
    e,
}: customActionType) {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );
    const { t } = useTranslation();

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <div className="d-flex justify-content-around w-50">
            {isProfileListArchived === true ? (
                <div id={`Duplication${e?.selectedRow?.iuud}`}>
                    <LockIconWithToolTip
                        message={t(`Consulter`)}
                        id={`Duplication${e?.selectedRow?.iuud}`}
                    />

                    <div
                        aria-label="consult archive profiles"
                        className="pfl_icn_consultArchivedProfile"
                        role="button"
                        tabIndex={0}
                        onClick={() => {
                            dynamicTableWrapperContext?.setRowData(
                                e.selectedRow
                            );
                            dynamicTableWrapperContext?.setLocalFilterActive(
                                false
                            );
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                true
                            );
                        }}
                    >
                        <ConsultIcon height={40} width={40} />
                    </div>
                </div>
            ) : (
                <div
                    id={`Modifier${e?.selectedRow?.iuud}`}
                    className={`cmn_icn_edit  ${
                        e.selectedRow.isProfileAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[PROFILE_ACCESS_ATHORIZATION_ID].Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                >
                    <LockIconWithToolTip
                        message={t(`Edit`)}
                        id={`Modifier${e?.selectedRow?.iuud}`}
                    />

                    <PencilIcon
                        style={{
                            cursor: "pointer",
                        }}
                        height={25}
                        width={25}
                        onClick={() => {
                            handleOnEditUser(e.selectedRow);
                            dynamicTableWrapperContext?.setLocalFilterActive(
                                false
                            );
                            dynamicTableWrapperContext?.setActionColumnDisplay(
                                true
                            );
                        }}
                    />
                </div>
            )}
        </div>
    );
}

export default CustomJsx;
