import React from "react";

export function UnlockIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={46}
            height={46}
            {...props}
        >
            <g data-name="Groupe 31809">
                <path
                    data-name="Trac\xE9 22228"
                    d="M27.374 19.136h-8.916v-.892a4.458 4.458 0 0 1 8.359-2.159.892.892 0 1 0 1.559-.866 6.241 6.241 0 0 0-11.7 3.025v1.27A4.458 4.458 0 0 0 14 23.594v5.35a4.463 4.463 0 0 0 4.458 4.459h8.916a4.463 4.463 0 0 0 4.458-4.458v-5.35a4.463 4.463 0 0 0-4.458-4.459Zm2.675 9.808a2.675 2.675 0 0 1-2.675 2.675h-8.916a2.675 2.675 0 0 1-2.675-2.676v-5.35a2.675 2.675 0 0 1 2.675-2.674h8.916a2.675 2.675 0 0 1 2.675 2.675Z"
                />
                <path
                    data-name="Trac\xE9 22229"
                    d="M22.916 24.182a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1Z"
                />
            </g>
        </svg>
    );
}
