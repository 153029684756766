import { VerticalDotsIcon, DocumentIcon } from "@aureskonnect/react-ui";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { USER_ARCHIVED_ACCESS_ATHORIZATION_ID } from "@constants/index";

type ArchivedMultiActionType = {
    selectedRows: any[];
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleHistorysOnClickEvent: Function;
};

function ArchivedMultiAction({
    selectedRows,
    setIsConfirmUnArchivedModal,
    handleHistorysOnClickEvent,
}: ArchivedMultiActionType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    function handleUnarchiveMultiAction(selectedRows: any[]) {
        dynamicTableWrapperContext?.setRowData(selectedRows);
        setIsConfirmUnArchivedModal(true);
    }
    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menu"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {/* Display multiple Account History */}
                <div
                    role="button"
                    tabIndex={-2}
                    className={`dropdown-item pl-1 cmn_btn_multipleHistorical ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleHistorysOnClickEvent(selectedRows);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                {/* Unarchive multiple accounts */}
                <div
                    role="button"
                    tabIndex={0}
                    className={` dropdown-item pl-1 cmn_btn_archivedAccounts ${
                        selectedRows &&
                        selectedRows?.length > 0 &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ARCHIVED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    } `}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleUnarchiveMultiAction(selectedRows);
                    }}
                >
                    <UnArchiveIcon width={25} height={25} />
                    <span className="ml-2">{t("UnArchiver account(s)")}</span>
                </div>
            </div>
        </div>
    );
}
export default ArchivedMultiAction;
