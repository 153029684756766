import { COLORS } from "@components/VerticalLayout/StyledSidebarMenu/colors";
import styled from "styled-components";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;


export const Wrapper = styled.div<WrapperPropsType>`
    .collapsed__clz {
        display: none;
        z-index: 1;
        position: fixed;

        margin-left: 220px !important;
        min-width: 220px;
        max-width: 270px;
        margin-top: -43px !important;
        background-color: ${({ backgroundColor }: WrapperPropsType) =>
            backgroundColor || COLORS.principal};
    }
    &:hover .collapsed__clz {
        display: block;
    }
`;