import React from "react";

export function BlockIcon(props: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={38}
            height={38.657}
            {...props}
        >
            <g data-name="Groupe 31807">
                <g data-name="01 align center">
                    <path
                        data-name="Trac\xE9 19865"
                        d="M18.499 10a9.5 9.5 0 1 0 9.5 9.5 9.5 9.5 0 0 0-9.5-9.5Zm0 1.584a7.88 7.88 0 0 1 5.009 1.792L12.375 24.513a7.914 7.914 0 0 1 6.124-12.929Zm0 15.841a7.88 7.88 0 0 1-5-1.792L24.632 14.5a7.914 7.914 0 0 1-6.133 12.925Z"
                        fill="#e30613"
                    />
                </g>
            </g>
        </svg>
    );
}
