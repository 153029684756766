/* eslint-disable jsx-a11y/no-static-element-interactions */
import {
    DocumentIcon,
    SettingsIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import classnames from "classnames";
import { DynamicTableWrapperContext } from "@components/Common";
import { BlockIcon } from "@components/Common/SvgIcons/BlockIcon";
import {
    TYPEBoutique,
    USER_ACCOUNT_ACCESS_ATHORIZATION_ID,
} from "@constants/index";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";

type ActionColumnType = {
    e: any;
    isConfirmArchivedModal: boolean;
    handleManageAccessOnClickEvent: Function;
    handleBlockAccountOnClickEvent: Function;
    setIsConfirmArchivedModal: Function;
    handleHistorysOnClickEvent: Function;
};
function ActionColumn({
    e,
    isConfirmArchivedModal,
    handleManageAccessOnClickEvent,
    handleBlockAccountOnClickEvent,
    setIsConfirmArchivedModal,
    handleHistorysOnClickEvent,
}: ActionColumnType) {
    const { t } = useTranslation();
    const TypeAccount = localStorage.getItem("TypeAccount");
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );


    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {TypeAccount !== TYPEBoutique ? (
                    <div
                        className={`dropdown-item pl-1 ctl_btn_manageAccess ${
                            e.selectedRow.original.isAdmin === true
                                ? "disabled_clz"
                                : ""
                        } ${
                            authorization[
                                USER_ACCOUNT_ACCESS_ATHORIZATION_ID
                            ] !== undefined &&
                            authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                                .Modifie
                                ? ""
                                : "disabled_clz"
                        }`}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleManageAccessOnClickEvent(e.selectedRow);
                        }}
                    >
                        <SettingsIcon width={35} height={25} />
                        <span className="ml-2">
                            {t("Manage access to accounts")}
                        </span>
                    </div>
                ) : null}
                <div
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        "dropdown-item pl-1 pointer__clz cmn_btn_historical"
                    )} ${
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    }`}
                    onClick={() => {
                        handleHistorysOnClickEvent(e.selectedRow);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                <div
                    className={`dropdown-item pl-1 ctl_btn_blockAccount ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleBlockAccountOnClickEvent(e.selectedRow);
                    }}
                >
                    <BlockIcon />
                    <span className="ml-2">{t("Block account")}</span>
                </div>
                <div
                    className={`dropdown-item pl-1 cmn_btn_archived ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        dynamicTableWrapperContext?.setRowData({
                            ...e.selectedRow,
                        });
                        setIsConfirmArchivedModal(!isConfirmArchivedModal);
                    }}
                >
                    <TrashIcon
                        width={35}
                        height={26}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span className="ml-2">{t("Archive account")}</span>
                </div>
            </div>
        </div>
    );
}

export default ActionColumn;
