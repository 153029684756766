import { useEffect, useState } from "react";

import { Outlet, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import classNames from "classnames";
import { useSelector, useDispatch } from "react-redux";

import { RootState } from "@store/.";
import { setCollapsed, setIsTablet } from "@store/features/layoutSlice";
import Footer from "./VerticalLayout/Footer";
import Sidebar from "./VerticalLayout/Sidebar";
import Header from "./VerticalLayout/Header";

import "./index.css";

export default function AuthLayout() {
    const TABLET_MAX_WIDTH = 992;
    const dismissAll = () => toast.dismiss();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const collapsed = useSelector(
        (state: RootState): boolean => state.layoutSlice.collapsed
    );
    const isTablet = useSelector(
        (state: RootState): boolean => state.layoutSlice.isTablet
    );

    const [showBrand, setShowBrand] = useState(false);
    const BRAND = "Access";
    dismissAll();

    const handleSideBarCollapse = () => {
        dispatch(setCollapsed());
    };

    useEffect(() => {
        if (window.innerWidth <= TABLET_MAX_WIDTH) {
            dispatch(setIsTablet(true));
        }
        const handleResize = () => {
            if (window.innerWidth <= TABLET_MAX_WIDTH) {
                dispatch(setIsTablet(true));
            } else {
                dispatch(setIsTablet(false));
            }
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [dispatch]);

    useEffect(() => {
        if (!isTablet) {
            setShowBrand(false);
        } else {
            collapsed ? setShowBrand(false) : setShowBrand(true);
        }
    }, [collapsed, isTablet]);

    return (
        <div
            style={{
                display: "grid",
                gridTemplateRows: "70px 1fr 80px",
                gridTemplateColumns: "auto 1fr  ",
                gridTemplateAreas: `
                    'sidebar header '
                    'sidebar content '
                    'sidebar footer '
                `,
                backgroundColor: "#f3f3f9",
                height: "100vh",
                overflow: "hidden",
            }}
        >
            <div
                className={classNames({
                    "d-none": !collapsed && isTablet,
                })}
                style={{
                    gridArea: "sidebar",
                    minHeight: "100vh",
                }}
            >
                {pathname !== "/customer-accounts" ? (
                    <Sidebar brand={BRAND} />
                ) : null}
            </div>
            <Header
                handleSideBarCollapse={handleSideBarCollapse}
                brand={BRAND}
                showBrand={showBrand}
            />

            <div
                style={{
                    gridArea: "content",
                    overflowY: "hidden",
                    overflowX: "auto",
                    width: "100%",
                    padding: "12px 5px",
                    display: "grid",
                    gridTemplateRows: "auto auto  1fr",
                }}
            >
                <Outlet />
            </div>
            <Footer />
        </div>
    );
}
