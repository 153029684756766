

import { createSlice } from "@reduxjs/toolkit";

type storeType = {

    isProfileListArchived: boolean
}
const initialState: storeType = {
    isProfileListArchived: false,


}

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setIsProfileListArchived: (state, action) => {
            state.isProfileListArchived = action.payload
        },


    }
});

export const { setIsProfileListArchived } = profileSlice.actions;

export default profileSlice.reducer;
