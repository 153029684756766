import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";
import {
    StatusBloquer,
    USER_BLOCKED_ACCESS_ATHORIZATION_ID,
} from "@constants/index";
import { AddUser } from "../UserAccounts/Adduser/AddUser";
import { BlockedAccountList } from "./BlockedAccountList";

function BlockedAccounts() {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                {authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                    undefined &&
                authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID].Consult ? (
                    <>
                        <StyledBreadcrumb
                            items={[
                                {
                                    item: "Access",
                                    link: "#",
                                },
                                {
                                    item: t(selectedCustomer),
                                    link: "#",
                                },
                                {
                                    item: t("User management"),
                                    link: "#",
                                },
                                {
                                    item: t("Blocked accounts"),
                                    link: "#",
                                },
                            ]}
                        />
                        <Container fluid>
                            <HeaderComponent
                                title={t(
                                    "Blocked user accounts are grouped in this section. It is possible to activate or archive them ."
                                )}
                            />
                            <DynamicTableWrapper
                                TableComponent={<BlockedAccountList />}
                                ActionColumnComponent={
                                    <AddUser userStatus={StatusBloquer} />
                                }
                            />
                        </Container>
                    </>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: "238px" }}
                    >
                        <img
                            alt="img"
                            src="./images/emptyAccounts.png"
                            style={{
                                width: "248px",
                                height: "248px",
                            }}
                        />
                        <span
                            style={{
                                width: "295px",
                                height: "40px",
                                textAlign: "left",
                                font: "normal normal 600 30px/40px Segoe UI",
                                color: "#495057",
                                marginLeft: "3rem",
                            }}
                        >
                            {t("No access allowed")}
                        </span>
                    </div>
                )}
            </motion.div>
        </React.Fragment>
    );
}

export default BlockedAccounts;
