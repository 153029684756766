/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import classname from "classnames";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";

import { setActiveMenu } from "@store/features/layoutSlice";
import { RootState } from "@store/.";
import { Collapse } from "reactstrap";
import { Wrapper }from "../../Common/StyledComponent/StyledWrapperMenu"
import { COLORS } from "./colors";
import { ArrowDownIcon } from "./ArrowDownIcon";
import { ArrowUpIcon } from "./ArrowUpIcons";

type WrapperPropsType = Pick<
    StyledSidebarMenuPropsType,
    "backgroundColor" | "collapsed"
>;

type MenuItemType = {
    index: number;
} & WrapperPropsType &
    MenuItemsType;


// eslint-disable-next-line sonarjs/cognitive-complexity
export function Menu({
    link,
    menuItem,
    subMenu,
    icon,
    index,
    collapsed,
    backgroundColor,
    menuItemClass,
    uid,
}: MenuItemType) {
    const location = useLocation();
    const [isSubMenuCollapsed, setIsSubMenuCollapsed] =
        React.useState<boolean>(false);
    const [isMenuCollapsed, setIsMenuCollapsed] =
        React.useState<boolean>(false);

    const dispatch = useDispatch();
    const activeMenu = useSelector(
        (state: RootState): number => state.layoutSlice.activeMenu
    );
    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    // const authorization = JSON.parse(localStorage.getItem("authorization") as string);

    return (
        <li>
            {/* Side Bar Menu Parent ---SIDEBAR OPEN--- */}
            {authorization[uid] !== undefined &&
            authorization[uid].Visible === true ? (
                <Link
                    aria-disabled={authorization[uid].Consult}
                    onClick={() => {
                        index === activeMenu
                            ? dispatch(setActiveMenu(-1))
                            : dispatch(setActiveMenu(index));

                        setIsSubMenuCollapsed(!isSubMenuCollapsed);
                    }}
                    role="menuitem"
                    tabIndex={0}
                    to={
                        subMenu !== undefined
                            ? "#"
                            : authorization[uid].Consult
                            ? link
                            : "#"
                    }
                    className={classname(
                        "d-flex align-items-center justify-content-between menu-item__clz",
                        {
                            "has-arrow": subMenu !== undefined,
                            active:
                                location.pathname === `/${link}` &&
                                subMenu === undefined,
                        },
                        menuItemClass
                    )}
                >
                    <div className="d-flex align-items-center justify-content-between menu-item__clz">
                        <div
                            style={{
                                marginRight: "7px",
                                fill: COLORS.white,
                            }}
                        >
                            {icon}
                        </div>

                        <span
                            className={classname({
                                "d-none": collapsed === true,
                            })}
                        >
                            {menuItem} {/* Menu Parent */}
                        </span>
                    </div>
                    {collapsed === true || subMenu === undefined
                        ? null
                        : activeMenu === index
                        ? (function () {
                              setActiveMenu(index);
                              return <ArrowUpIcon />;
                          })()
                        : (function () {
                              setActiveMenu(-1);
                              return <ArrowDownIcon />;
                          })()}
                </Link>
            ) : null}

            {/* Side Bar Menu Parent ---SIDEBAR CLOSE---*/}
            {collapsed === true ? (
                <ul className="collapsed-submenu__clz">
                    {subMenu !== undefined ? (
                        <li
                            key={shortid.generate()}
                            className={`menuitem-collapsed-header__clz ${menuItemClass}`}
                        >
                            <span>{menuItem}</span> {/* Menu Parent */}
                        </li>
                    ) : (
                        <li
                            key={shortid.generate()}
                            style={{ padding: "0px" }}
                            className="menuitem-collapsed-header__clz"
                        >
                            <Link to={link}>{menuItem}</Link>
                        </li>
                    )}

                    {subMenu !== undefined
                        ? subMenu?.map(
                              ({
                                  link,
                                  menuItem,
                                  subMenu,
                                  uid,
                              }: MenuItemsType) => {
                                  return (
                                      <Wrapper
                                          key={shortid.generate()}
                                          backgroundColor={backgroundColor}
                                      >
                                          {authorization[uid] !== undefined &&
                                              authorization[uid].Visible ===
                                                  true && (
                                                  <li className="collapsed-sub-submenu__clz">
                                                      <Link
                                                          to={
                                                              authorization[uid]
                                                                  .Consult ? link : '#'
                                                          }
                                                      >
                                                          {menuItem}
                                                      </Link>
                                                  </li>
                                              )}
                                          {subMenu !== undefined ? (
                                              <ul className="collapsed__clz">
                                                  <li
                                                      key={shortid.generate()}
                                                      className="menuitem-collapsed-header__clz "
                                                  >
                                                      <span>{menuItem}</span>
                                                  </li>
                                                  {subMenu.map(
                                                      ({
                                                          link,
                                                          menuItem,
                                                          uid
                                                      }: MenuItemsType) => {
                                                          return (
                                                              <li
                                                                  key={shortid.generate()}
                                                              >
                                                                  {authorization[
                                                                      uid
                                                                  ] !==
                                                                      undefined &&
                                                                      authorization[
                                                                          uid
                                                                      ]
                                                                          .Visible ===
                                                                          true && (
                                                                          <li>
                                                                              <Link
                                                                                  to={
                                                                                      authorization[
                                                                                          uid
                                                                                      ]
                                                                                          .Consult
                                                                                          ? link
                                                                                          : "#"
                                                                                  }
                                                                              >
                                                                                  {
                                                                                      menuItem
                                                                                  }
                                                                              </Link>
                                                                          </li>
                                                                      )}
                                                              </li>
                                                          );
                                                      }
                                                  )}
                                              </ul>
                                          ) : null}
                                      </Wrapper>
                                  );
                              }
                          )
                        : null}
                </ul>
            ) : null}

            {/* Side Bar Menu Children */}
            {subMenu !== undefined && collapsed !== true ? (
                <Collapse isOpen={activeMenu === index}>
                    <ul>
                        {subMenu?.map(
                            ({
                                link,
                                menuItem,
                                icon,
                                subMenu,
                                subMenuClass,
                                uid,
                            }: // eslint-disable-next-line sonarjs/cognitive-complexity
                            MenuItemsType) => {
                                const Icon: any =
                                    icon !== undefined ? icon : null;
                                return (
                                    <li
                                        key={shortid.generate()}
                                        className={subMenuClass}
                                    >
                                        {
                                            /* Menu Children */
                                            authorization[uid] !== undefined &&
                                            authorization[uid].Visible ===
                                                true ? (
                                                <Link
                                                    aria-disabled={
                                                        authorization[uid]
                                                            .Consult
                                                    }
                                                    to={
                                                        subMenu !== undefined
                                                            ? "#"
                                                            : authorization[uid]
                                                                  .Consult
                                                            ? link
                                                            : "#"
                                                    }
                                                    onClick={() => {
                                                        if (
                                                            subMenu !==
                                                            undefined
                                                        ) {
                                                            setIsMenuCollapsed(
                                                                !isMenuCollapsed
                                                            );
                                                        }
                                                    }}
                                                    className={classname(
                                                        "d-flex align-items-center justify-content-between",
                                                        {
                                                            active:
                                                                location.pathname ===
                                                                `/${link}`,
                                                        }
                                                    )}
                                                    style={{
                                                        padding:
                                                            ".4rem 1.5rem .4rem 3.5rem",
                                                    }}
                                                >
                                                    {icon ? (
                                                        <Icon
                                                            style={{
                                                                marginRight:
                                                                    "7px",
                                                                height: "1.25em",
                                                                width: "1.25em",
                                                                fill: COLORS.white,
                                                            }}
                                                        />
                                                    ) : null}
                                                    {menuItem}
                                                    {subMenu ===
                                                    undefined ? null : isMenuCollapsed ===
                                                      true ? (
                                                        <ArrowUpIcon />
                                                    ) : (
                                                        <ArrowDownIcon />
                                                    )}
                                                </Link>
                                            ) : (
                                                <div />
                                            )
                                        }

                                        {subMenu !== undefined ? (
                                            <Collapse isOpen={isMenuCollapsed}>
                                                <ul>
                                                    {subMenu.map(
                                                        ({
                                                            link,
                                                            menuItem,
                                                            icon,
                                                            subMenu,
                                                        }: MenuItemsType) => {
                                                            const Icon: any =
                                                                icon !==
                                                                undefined
                                                                    ? icon
                                                                    : null;
                                                            return (
                                                                <li
                                                                    key={shortid.generate()}
                                                                >
                                                                    <Link
                                                                        to={
                                                                            link
                                                                        }
                                                                        className={classname(
                                                                            "maher d-flex align-items-center justify-content-between",
                                                                            {
                                                                                active:
                                                                                    location.pathname ===
                                                                                    `/${link}`,
                                                                            }
                                                                        )}
                                                                        style={{
                                                                            padding:
                                                                                ".4rem 1.5rem .4rem 4.5rem",
                                                                        }}
                                                                    >
                                                                        {icon ? (
                                                                            <Icon
                                                                                style={{
                                                                                    marginRight:
                                                                                        "7px",
                                                                                    height: "1.25em",
                                                                                    width: "1.25em",
                                                                                    fill: COLORS.white,
                                                                                }}
                                                                            />
                                                                        ) : null}
                                                                        {
                                                                            menuItem
                                                                        }
                                                                        {subMenu ===
                                                                        undefined ? null : isSubMenuCollapsed ===
                                                                          true ? (
                                                                            <ArrowDownIcon />
                                                                        ) : (
                                                                            <ArrowUpIcon />
                                                                        )}
                                                                    </Link>
                                                                </li>
                                                            );
                                                        }
                                                    )}
                                                </ul>
                                            </Collapse>
                                        ) : null}
                                    </li>
                                );
                            }
                        )}
                    </ul>
                </Collapse>
            ) : null}
        </li>
    );
}
