/* This Page Contain Single action on BLOCKED user account
    - UnBlock User Account
    - Archive User Account

*/
import {
    DocumentIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { UnlockIcon } from "@components/Common/SvgIcons/UnlockIcon";
import React from "react";
import { useTranslation } from "react-i18next";
import { DynamicTableWrapperContext } from "@components/Common";
import classnames from "classnames";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { USER_BLOCKED_ACCESS_ATHORIZATION_ID } from "@constants/index";

type BlockActionColumnType = {
    e: any;
    setIsConfirmBlockModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleHistorysOnClickEvent: Function;
};
function BlockActionColumn({
    e,
    setIsConfirmBlockModal,
    setIsConfirmArchivedModal,
    handleHistorysOnClickEvent,
}: BlockActionColumnType) {
    const { t } = useTranslation();
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    function handleUnBlockAccountOnClickEvent(user: any) {
        setIsConfirmBlockModal(true);
        dynamicTableWrapperContext?.setRowData(user);
    }
    function handleArchiveAccountOnClickEvent(user: any) {
        setIsConfirmArchivedModal(true);
        dynamicTableWrapperContext?.setRowData(user);
    }

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                <div
                    role="button"
                    tabIndex={0}
                    style={{ cursor: "pointer" }}
                    className={`${classnames(
                        " dropdown-item pl-1 pointer__clz cmn_btn_historical"
                    )} ${
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Consult
                            ? ""
                            : "disabled_clz"
                    }`}
                    onClick={() => {
                        handleHistorysOnClickEvent(e.selectedRow);
                    }}
                >
                    <DocumentIcon width={35} height={25} />
                    <span className="ml-2">{t("History")}</span>
                </div>
                <div
                    role="button"
                    tabIndex={-1}
                    className={`cbl_btn_unlockAccount d-flex  align-items-center  dropdown-item pl-1 ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleUnBlockAccountOnClickEvent(e.selectedRow);
                    }}
                >
                    <UnlockIcon width={45} height={39} />
                    <span>{t("UnBlock account")}</span>
                </div>
                <div
                    role="button"
                    tabIndex={0}
                    className={`dropdown-item d-flex  align-items-center pl-1 cmn_btn_archived ${
                        e.selectedRow.original.isAdmin === true
                            ? "disabled_clz"
                            : ""
                    } ${
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID] !==
                            undefined &&
                        authorization[USER_BLOCKED_ACCESS_ATHORIZATION_ID]
                            .Modifie
                            ? ""
                            : "disabled_clz"
                    }`}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                        handleArchiveAccountOnClickEvent(e.selectedRow);
                    }}
                >
                    <TrashIcon
                        width={45}
                        height={26}
                        fill="red"
                        style={{ cursor: "pointer" }}
                    />
                    <span>{t("Archive account")}</span>
                </div>
            </div>
        </div>
    );
}
export default BlockActionColumn;
