import { createSlice } from "@reduxjs/toolkit";

type StateType = {
    isVisible: boolean;
};
const initialState: StateType = {
    isVisible: true,
};

export const headerCompoHeighSlice = createSlice({
    name: "headerCompoHeighSlice",
    initialState,
    reducers: {
        setVisible: (state) => {
            state.isVisible = !state.isVisible;
        },
        resetHeighSlice: (state) => {
            state.isVisible = true;
        },
    },
});

export const { setVisible, resetHeighSlice } = headerCompoHeighSlice.actions;

export default headerCompoHeighSlice.reducer;
