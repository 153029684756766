import React from "react";
import {
    DocumentIcon,
    SettingsIcon,
    TrashIcon,
    VerticalDotsIcon,
} from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";
import classnames from "classnames";

import { DynamicTableWrapperContext } from "@components/Common";
import { UnArchiveIcon } from "@components/Common/SvgIcons/UnArchiveIcon";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "@store/index";
import { PROFILE_ACCESS_ATHORIZATION_ID } from "@constants/index";

type ColumnActiveType = {
    isProfileListArchived: boolean;
    isConfirmUnArchivedModal: boolean;
    isConfirmArchivedModal: boolean;
    setIsConfirmUnArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    setIsConfirmArchivedModal: React.Dispatch<React.SetStateAction<boolean>>;
    handleManagePermissionsOnClickEvent: Function;
    e: any;
    handleHistoryOnClickEvent: Function;
};

// eslint-disable-next-line sonarjs/cognitive-complexity
function ColumnActive({
    isProfileListArchived,
    isConfirmUnArchivedModal,
    setIsConfirmUnArchivedModal,
    setIsConfirmArchivedModal,
    handleManagePermissionsOnClickEvent,
    isConfirmArchivedModal,
    e,
    handleHistoryOnClickEvent,
}: ColumnActiveType) {
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    const { t } = useTranslation();

    // Handle UNarchive action for a user profile
    function handleUnArchiveAction() {
        dynamicTableWrapperContext?.setRowData({
            ...e.selectedRow,
        });
        setIsConfirmUnArchivedModal(!isConfirmUnArchivedModal);
    }

    // Handle manage permission for a user profile
    function handleManagePermissionsAction() {
        // dynamicTableWrapperContext?.setActionColumnDisplay(false);
        handleManagePermissionsOnClickEvent(e.selectedRow);
    }

    // Handle archive action for a user profile
    async function handleArchiveAction() {
        const ProfileId = e.selectedRow.original.uid;
        try {
            const apiUrl = `${process.env.REACT_APP_ACCESS_API_URL}/check-profile-used?profilesUUid='${ProfileId}'`;
            mutate(
                apiUrl,
                await fetch(apiUrl, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    method: "GET",
                })
                    .then((response) => response.json())
                    .then((data) => {
                        if (data.check) {
                            throw Error();
                        }
                        dynamicTableWrapperContext?.setRowData({
                            ...e.selectedRow,
                        });
                        setIsConfirmArchivedModal(!isConfirmArchivedModal);
                    })
            );
        } catch (error) {
            toast.error(
                `${t(
                    "Please, cannot archive profile used by one or more user accounts"
                )}!`,
                {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 2000,
                    theme: "colored",
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                }
            );
        }
    }

    return (
        <div className="dropdown">
            <VerticalDotsIcon
                className="cmn_drp_menuAction"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                style={{ cursor: "pointer" }}
                height={25}
                width={25}
                fill="black"
            />
            <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                {isProfileListArchived === true ? (
                    <>
                        <div
                            className={`dropdown-item pl-1 pointer__clz cmn_btn_historical ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[PROFILE_ACCESS_ATHORIZATION_ID]
                                    .Consult
                                    ? ""
                                    : "disabled_clz"
                            }`}
                            role="button"
                            tabIndex={-1}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleHistoryOnClickEvent(e.selectedRow);
                            }}
                        >
                            <DocumentIcon width={35} height={25} />
                            <span className="ml-2">{t("History")}</span>
                        </div>
                        <div
                            className={`dropdown-item pl-1 pfl_btn_unarchiveProfile  ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[PROFILE_ACCESS_ATHORIZATION_ID]
                                    .Modifie
                                    ? ""
                                    : "disabled_clz"
                            }`}
                            role="button"
                            tabIndex={0}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleUnArchiveAction();
                            }}
                        >
                            <UnArchiveIcon width={28} height={28} />
                            <span className="ml-2">
                                {t("Unarchive profile")}
                            </span>
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            role="button"
                            tabIndex={0}
                            className={`dropdown-item pl-1 pfl_btn_managePermissions ${
                                e.selectedRow.original.isProfileAdmin === true
                                    ? "disabled_clz"
                                    : ""
                            } ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[PROFILE_ACCESS_ATHORIZATION_ID]
                                    .Modifie
                                    ? ""
                                    : "disabled_clz"
                            } `}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleManagePermissionsAction();
                                dynamicTableWrapperContext?.setCheckModifMultiple(
                                    false
                                );
                            }}
                        >
                            <SettingsIcon width={35} height={25} />
                            <span className="ml-2">
                                {t("Manage permissions")}
                            </span>
                        </div>
                        <div
                            className={`${classnames(
                                " dropdown-item pl-1 pointer__clz cmn_btn_historical"
                            )} ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[PROFILE_ACCESS_ATHORIZATION_ID]
                                    .Consult
                                    ? ""
                                    : "disabled_clz"
                            }`}
                            role="button"
                            tabIndex={-1}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleHistoryOnClickEvent(e.selectedRow);
                            }}
                        >
                            <DocumentIcon width={35} height={25} />
                            <span className="ml-2">{t("History")}</span>
                        </div>
                        <div
                            role="button"
                            tabIndex={-2}
                            className={`dropdown-item pl-1 cmn_btn_archived ${
                                e.selectedRow.original.isProfileAdmin === true
                                    ? "disabled_clz"
                                    : ""
                            } ${
                                authorization[
                                    PROFILE_ACCESS_ATHORIZATION_ID
                                ] !== undefined &&
                                authorization[PROFILE_ACCESS_ATHORIZATION_ID]
                                    .Modifie
                                    ? ""
                                    : "disabled_clz"
                            }`}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                                handleArchiveAction();
                            }}
                        >
                            <TrashIcon
                                width={35}
                                height={26}
                                fill="red"
                                style={{ cursor: "pointer" }}
                            />
                            <span className="ml-2">{t("Archive profile")}</span>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
}

export default ColumnActive;
