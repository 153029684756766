import React from "react";
import { StyledTooltip } from "@aureskonnect/react-ui";
import { useToggle } from "./useToggle";

type LockIconWithToolTipPropsType = {
    id: string;
    message: string;
};

export default function LockIconWithToolTip({
    id,
    message,
}: LockIconWithToolTipPropsType) {
    const [tooltipOpen, setTooltipOpen] = useToggle();

    // const toggle = () => setTooltipOpen();

    return id ? (
        <StyledTooltip
            autohide
            toggle={setTooltipOpen}
            style={{
                backgroundColor: "#EEEEEE",
                color: "black",
                boxShadow: "0px 1px 3px #616060c6",
            }}
            flip
            placement="top"
            target={id}
            isOpen={tooltipOpen}
        >
            {message}
        </StyledTooltip>
    ) : null;
}
