import React from "react";
import { motion } from "framer-motion/dist/framer-motion";
import { useTranslation } from "react-i18next";
import { Container } from "reactstrap";
import { StyledBreadcrumb } from "@aureskonnect/react-ui";

import { PageTitle } from "@components/index";
import HeaderComponent from "@components/VerticalLayout/HeaderComponent";
import DynamicTableWrapper from "@components/Common/DynamicTableWrapper";

import { useSelector } from "react-redux";
import { RootState } from "@store/index";

import {
    StatusActive,
    USER_ACCOUNT_ACCESS_ATHORIZATION_ID,
} from "@constants/index";
import { UserAccountList } from "./UserAccountList";
import { AddUser } from "./Adduser/AddUser";

export default function UserAccounts() {
    const { t } = useTranslation();
    const selectedCustomer = localStorage.getItem(
        "customerAccountName"
    ) as string;

    const authorization = useSelector(
        (state: RootState) => state.ProfileAuthorizationSlice.data
    );

    return (
        <React.Fragment>
            <PageTitle title={t("User management")} />
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
                className="page-content"
            >
                {authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID] !==
                    undefined &&
                authorization[USER_ACCOUNT_ACCESS_ATHORIZATION_ID].Consult ? (
                    <>
                        <StyledBreadcrumb
                            items={[
                                {
                                    item: "Access",
                                    link: "#",
                                },
                                {
                                    item: t(selectedCustomer),
                                    link: "#",
                                },
                                {
                                    item: t("User management"),
                                    link: "#",
                                },
                                {
                                    item: t("User accounts"),
                                    link: "#",
                                },
                            ]}
                        />
                        <Container fluid>
                            <HeaderComponent
                                title={t(
                                    "It is essential to create accounts for the users of each customer account in order to be able, according to the authorizations of the profile assigned to this user, to access the different ETK modules"
                                )}
                            />
                            <DynamicTableWrapper
                                TableComponent={<UserAccountList />}
                                ActionColumnComponent={
                                    <AddUser userStatus={StatusActive} />
                                }
                            />
                        </Container>
                    </>
                ) : (
                    <div
                        className="d-flex flex-column justify-content-center align-items-center"
                        style={{ marginTop: "238px"}}
                    >
                        <img
                            alt="img"
                            src="./images/emptyAccounts.png"
                            style={{
                                width: "248px",
                                height: "248px",
                            }}
                        />
                        <span
                            style={{
                                width: "295px",
                                height: "40px",
                                textAlign: "left",
                                font: "normal normal 600 30px/40px Segoe UI",
                                color: "#495057",
                                marginLeft: "3rem"
                            }}
                        >
                            {t("No access allowed")}
                        </span>
                    </div>
                )}
            </motion.div>
        </React.Fragment>
    );
}
