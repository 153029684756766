export function isEmailValid(email: string) {
    const EMAIL_VALIDATION_REGEX = /\S+@\S+\.\S+/;
    return EMAIL_VALIDATION_REGEX.test(email);
}

export function truncateString(string: string, maxLength: number): string {
    return string?.length > maxLength
        ? `${string?.slice(0, maxLength)}...`
        : string;
}

export function toLowerCase(object: any): any {
    if (Array.isArray(object)) {
       return object.map(toLowerCase);
       // eslint-disable-next-line
    } else if (object !== null && typeof object === "object") {
       return Object.fromEntries(
            Object.entries(object).map(([key, value]: any) => [
                key,
                toLowerCase(value),
            ])
        );
    } else if (typeof object === "string") {
       return object.toLowerCase();
    }else{
        return object;
    }

}
