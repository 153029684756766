import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { StyledButton } from "@aureskonnect/react-ui";
import { useTranslation } from "react-i18next";

import { DominantColor } from "@components/Common";
import { DynamicTableWrapperContext } from "@components/Common/DynamicTableWrapper";

import { ModalContentWrapper } from "./ModalContentWrapper/ModalContentWrapper";
import ConfirmPermissionModification from "./ConfirmPermissionModification";
import AuthorizationMultiProfileModel from "./ModalContentWrapper/authorizationMultiProfileModel";

import "../index.css";

type MangeProfilePermissionsType = {
    isOpen: boolean;
    setIsOpen: Function;
    handleSaveButtonOnClickEvent: Function;

    editProfile: boolean;
    setEditActionProfile?: Function;
    listSelectedData?: any;
    setlistSelectedData: Function;
};
function MangeProfilePermissions({
    isOpen,
    setIsOpen,
    handleSaveButtonOnClickEvent,
    setlistSelectedData,
    listSelectedData,
    editProfile,
    setEditActionProfile,
}: MangeProfilePermissionsType) {
    const { t } = useTranslation();

    function handleCloseModal() {
        setIsOpen(!isOpen);
        setEditActionProfile?.(false);
    }

    const [
        isConfirmPermissionModificationOpened,
        setIsConfirmPermissionModification,
    ] = React.useState(false);

    const [authMultiprofileModeOpen, setAuthMultiprofileModeOpen] =
        React.useState<boolean>(false);
    const dynamicTableWrapperContext = React.useContext(
        DynamicTableWrapperContext
    );

    const SelectedProfil = dynamicTableWrapperContext?.rowData?.original;
    const dominantColor: string = DominantColor();
    const [selectedRows, setSelectedRows] = React.useState<any[]>([]);

    const msgHeader =
        !editProfile || SelectedProfil?.Désignation === undefined
            ? t("PROFILE PERMISSIONS")
            : `${t("PROFILE PERMISSIONS")} : ${SelectedProfil?.Désignation}`;
    return (
        <>
            <Modal
                centered
                backdrop="static"
                fade={false}
                scrollable
                isOpen={isOpen}
                className="modal-wrapper__clz"
            >
                <ModalHeader
                    toggle={(): any => {
                        (listSelectedData &&
                            Object.values(listSelectedData).length > 0) ||
                        dynamicTableWrapperContext?.checkProfileAuthModelModification
                            ? setIsConfirmPermissionModification(true)
                            : handleCloseModal();
                    }}
                    className="text-uppercase cursor__clz"
                >
                    <span
                        className="pfl_txt_profilePermission"
                        style={{
                            textAlign: "left",
                            font: " normal normal bold 20px/14px Segoe UI",
                            color: "#2B2828",
                            fontWeight: 600,
                        }}
                    >
                        {msgHeader}
                    </span>
                </ModalHeader>
                <ModalBody  style={{ overflowY: "unset" }}>
                    <ModalContentWrapper
                        setlistSelectedData={setlistSelectedData}
                        editProfile={editProfile}
                        setAuthMultiprofileModeOpen={
                            setAuthMultiprofileModeOpen
                        }
                        selectedRows={selectedRows}
                        setSelectedRows={setSelectedRows}
                    />
                </ModalBody>
                <ModalFooter>
                    <StyledButton
                        className="pfl_btn_validatePopup"
                        rounded
                        variant={dominantColor}
                        onClick={() => {
                            handleSaveButtonOnClickEvent();
                            setSelectedRows([]);
                            dynamicTableWrapperContext?.setMultiAuthorizationProfileData(
                                {}
                            );
                        }}
                    >
                        {t("Validate")}
                    </StyledButton>
                </ModalFooter>
            </Modal>
            <ConfirmPermissionModification
                // eslint-disable-next-line react/jsx-no-bind
                handleCloseModal={handleCloseModal}
                isOpen={isConfirmPermissionModificationOpened}
                setIsOpen={setIsConfirmPermissionModification}
                handleSaveButtonOnClickEvent={handleSaveButtonOnClickEvent}
                setlistSelectedData={setlistSelectedData}
                setEditActionProfile={setEditActionProfile}
            />
            <AuthorizationMultiProfileModel
                show={authMultiprofileModeOpen}
                setShow={setAuthMultiprofileModeOpen}
                selectedRows={selectedRows}
            />
        </>
    );
}

export default MangeProfilePermissions;
